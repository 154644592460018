<template>
  <div class="public-supportbar uti_text-align-center">
    <h4>
      {{ $t('layout.footer.any_questions') }}
      <span
        class="subline"
      >
        {{ $t('layout.footer.our_support_team_is_available_for_you') }}
      </span>
    </h4>
    <p>
      <PfIcon
        name="phone"
        class="uti_text-space-after"
      />
      <i18n-t
        scope="global"
        keypath="layout.footer.planfred_hotline"
      >
        <template #number>
          <a
            :href="`tel:${ $t('data.phone_number') }`"
          >
            {{ $t('data.phone_number') }}
          </a>
        </template>
      </i18n-t>
      <br>
      <PfIcon
        name="email"
        class="uti_text-space-after"
      />
      <i18n-t
        scope="global"
        keypath="layout.footer.planfred_email"
      >
        <template #email>
          <a
            :href="`mailto:${ $t('data.email_support') }`"
          >
            {{ $t('data.email_support') }}
          </a>
        </template>
      </i18n-t>
    </p>
  </div>
</template>
