<template>
  <div
    class="comv2_button-bar"
    :class="{
      'com--lg': size === 'lg',
      'com--sm': size === 'sm',
      'comv2_button-bar--border-none': !hasBorder,
      'comv2_button-bar--inverse': isInversed
    }"
  >
    <div
      v-if="$slots['alt-action']"
      class="comv2_button-bar__alt-action-slot"
    >
      <slot name="alt-action" />
    </div>

    <div
      v-if="$slots['default']"
      class="comv2_button-bar__action-slot"
    >
      <slot />
    </div>

    <div
      class="comv2_button-bar__alert-slot"
    >
      <slot name="error" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '',
    },

    hasBorder: {
      type: Boolean,
      default: true,
    },

    isInversed: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
