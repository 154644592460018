<template>
  <div
    class="com_form-group"
  >
    <div
      class="com_form__label-list"
    >
      <div class="com_form__label-list-item">
        <label
          :for="`dsgvo-${uid}`"
          class="com_form__radio-check-label"
          data-testid="checkbox-dsgvo"
        >
          <input
            :id="`dsgvo-${uid}`"
            v-model="confirmedDsgvo"
            class="com_form__radio-check-box    obj_checkbox"
            name="dsgvo"
            type="checkbox"
            required
          >
          <span>
            {{ $t('feature.agb.i_read_privacy_policy') }}

            <a
              :href="$t('data.pf_links.privacy_policy')"
              target="_blank"
              class="uti_white-space-nowrap"
            >
              {{ $t('feature.agb.jump_to_privacy_policy') }}
            </a>
          </span>
        </label>
        <br>
        <MessageBoxWarning
          v-if="showValidationError && !form.confirmedDsgvo"
          :message="$t('feature.agb.please_accept_privacy_policy')"
          :is-form="true"
          data-testid="validation-error-dsgvo"
        />
      </div>

      <div class="com_form__label-list-item">
        <label
          :for="`agb-${uid}`"
          class="com_form__radio-check-label"
          data-testid="checkbox-agb"
        >
          <input
            :id="`agb-${uid}`"
            v-model="confirmedAgb"
            class="com_form__radio-check-box    obj_checkbox"
            name="agb"
            type="checkbox"
            required
          >
          <span>
            {{ $t('feature.agb.i_accept_terms') }} –
            <a
              :href="$t('data.pf_links.impressum')"
              target="_blank"
              class="uti_white-space-nowrap"
            >
              {{ $t('feature.agb.jump_to_terms') }}
            </a>
          </span>
        </label>
        <br>
        <MessageBoxWarning
          v-if="showValidationError && !form.confirmedAgb"
          :message="$t('feature.agb.please_accept_terms')"
          :is-form="true"
          data-testid="validation-error-agb"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MessageBoxWarning from '@/components/MessageBoxWarning.vue'
import generateComponentUid from '@/utils/generate-component-uid.js'

export default {
  components: {
    MessageBoxWarning,
  },

  props: {
    form: {
      type: Object,
      required: true,
      validator (value) {
        if (!('confirmedAgb' in value && 'confirmedDsgvo' in value)) {
          throw new Error('Form must have properties "confirmedAgb" and "confirmedDsgvo"')
        }

        return true
      },
    },

    showValidationError: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'on-change-confirmed-agb',
    'on-change-confirmed-dsgvo',
  ],

  data () {
    return {
      uid: generateComponentUid(this),
    }
  },

  computed: {
    confirmedAgb: {
      get () {
        return this.form.confirmedAgb
      },

      set (confirmedAgb) {
        this.$emit('on-change-confirmed-agb', confirmedAgb)
      },
    },

    confirmedDsgvo: {
      get () {
        return this.form.confirmedDsgvo
      },

      set (confirmedDsgvo) {
        this.$emit('on-change-confirmed-dsgvo', confirmedDsgvo)
      },
    },
  },
}
</script>
