import { getFileAssignmentCandidates } from '@client-shared/api/plans.api'
import generateRandomId from '@client-shared/utils/generate-random-id'
import { sortClipboardFileAssignmentsByNameAsc } from '@client-shared/utils/sort-functions'

import constants from '@/config/constants'

/**
 * To understand the structure of the ClipboardFileAssignment list:
 *
 * - The Overlay consists of AssignmentPackages
 * - An AssignmentPackage can contain X CandidatePackages. Usually there should be exactly 1 CandidatePackge. If there are more, it's an error. This can happen, if a clipboardFilePackage matches multiple plans
 * - A CandidatePackage consists of Y Candidates. Usually there should be exactly 1 Candidate. If there are more, it's an error. This can happen, if multiple clipboardFilePackages matches the same plan
 * - A Candidate has exactly 1 ClipboardFilePackage. A ClipboardFilePackage consists of printFiles and dwgFiles. Usually there is exactly 1 printFile and 1 dwgFile. If there are more than 1, it's an error (you can only assign 1 print file and 1 dwg file in a plan)
 */

/**
 * @typedef {Object} AssignmentPackage
 *
 * @property {Object} _id                                               Generated, unique id for AssignmentPackage
 * @property {Boolean} isSelected                                       Indicates whether the package is selected in the UI
 * @property {Boolean} hasConflict                                      Indicates if any of the candidatePackages has a conflict
 * @property {Array.<CandidatePackage>} candidatePackages
 */

/**
 * @typedef {Object} CandidatePackage
 *
 * @property {Object} [plan]                                            optional. If there is no item, there is NO matching plan
 * @property {Boolean} hasConflict                                      Is true if any of the conflictDetails is true
 * @property {Object} conflictDetails                                   Describes why a conflict is happening
 * @property {Boolean} conflictDetails.hasConflictMultipleCandidates    When there is more than 1 candidate, it's an error
 * @property {Boolean} conflictDetails.hasConflictMultiplePrintFiles    When there are multiple print files with the same filename (like test.pdf AND test.docx) it's an error
 * @property {Boolean} conflictDetails.hasConflictMultipleDwgFiles      When there are multiple dwg files with the same filename (like test.dwg AND test.cad) it's an error
 * @property {Boolean} conflictDetails.hasConflictingPlanIds            When there is another plan which matches the exact same file, it's an error
 * @property {Boolean} conflictDetails.hasConflictOnlyPrintAllowed      If the clipboard contains PDF and DWG files we can't do a proper matching. If it's only PDFs, or only DWGs or only PDFs with DWGs of the same file name (excluding extension), it works
 * @property {Boolean} conflictDetails.hasConflictWrongExtension        Indicates that there was no assignment AND the file doesn't have a valid extension for PRINT / CAD file
 * @property {Boolean} conflictDetails.hasConflictMissingPlan           We didn't find any matching plan for the provided file
 * @property {Array.<Candidate>} candidates                             There should be only 1 candidate. If there are more, it's an error
 */

/**
 * @typedef {Object} Candidate
 *
 * @property {Array.<ClipboardFilePackage>} clipboardFilePackage
 * @property {Array.<String>} conflictingPlanIds                        OTHER PlanIds where the same clipboardFilePackage is a match
 * @property {Object} matchingDetails                                   Describes why a match is happening
 * @property {Boolean} matchingDetails.doesMatchPlanNameExact           The plan name is part of the fileName (no modification is performed)
 * @property {Boolean} matchingDetails.doesMatchPlanNameCleaned         The CLEANED plan name is part of the CLEANED fileName (cleaning includes removing dates and unnecessary strings like "Vorabzug")
 * @property {Boolean} matchingDetails.hasPrintFileMismatch             This flag indicates if the previous revision had a printFile but the clipboardFilePackage doesn't
 * @property {Boolean} matchingDetails.hasDwgFileMismatch               This flag indicates if the previous revision had a dwg but the clipboardFilePackage doesn't
 * @property {Boolean} matchingDetails.score                            The score is calculated on the server based on the similarity of the given fileName compared to the fileNames of a revision
 * @property {String} revision.index
 * @property {String} revision.comment
 * @property {Object} revision.pdf_file
 * @property {String} revision.pdf_file.filename
 * @property {String} revision.pdf_file.filesize
 * @property {String} revision.pdf_file.key
 * @property {Object} revision.dwg_file
 * @property {String} revision.dwg_file.filename
 * @property {String} revision.dwg_file.filesize
 * @property {String} revision.dwg_file.key
 */

/**
 * @typedef {Object} ClipboardFilePackage
 *
 * @property {String} fileNameWithoutExtension
 * @property {Array.<ClipboardFile>} printFiles                         Should contain only 1 file. If there are more present, it's an error. The array contains the clipboardFiles
 * @property {Array.<ClipboardFile>} dwgFiles                           Should contain only 1 file. If there are more present, it's an error. The array contains the clipboardFiles
 */

export default {
  namespaced: true,

  state: () => {
    return {
      /** @type {Array.<AssignmentPackage>} */
      assignmentPackages: [],

      isLoading: false,
    }
  },

  mutations: {
    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },

    SET_ASSIGNMENT_PACKAGES (state, assignmentPackages) {
      state.assignmentPackages = assignmentPackages
    },

    UPDATE_INDEX (state, {
      assignmentPackageId,
      planId,
      fileNameWithoutExtension,
      index,
    }) {
      const assignmentPackage = state.assignmentPackages.find(assignmentPackage => assignmentPackage._id === assignmentPackageId)

      if (!assignmentPackage) {
        throw new Error(`No assignmentPackage with id ${assignmentPackageId} found`)
      }

      const candidateListPackage = assignmentPackage.candidatePackages.find(candidateListPackage => candidateListPackage.plan._id === planId)

      if (!candidateListPackage) {
        throw new Error(`No candidateListPackage with planId ${planId} found`)
      }

      const candidate = candidateListPackage.candidates.find(candidate => candidate.clipboardFilePackage.fileNameWithoutExtension === fileNameWithoutExtension)

      if (!candidate) {
        throw new Error(`No candidate with fileNameWithoutExtension ${fileNameWithoutExtension} found`)
      }

      candidate.revision.index = index
    },

    UPDATE_COMMENT (state, {
      assignmentPackageId,
      planId,
      fileNameWithoutExtension,
      comment,
    }) {
      const assignmentPackage = state.assignmentPackages.find(assignmentPackage => assignmentPackage._id === assignmentPackageId)

      if (!assignmentPackage) {
        throw new Error(`No assignmentPackage with id ${assignmentPackageId} found`)
      }

      const candidateListPackage = assignmentPackage.candidatePackages.find(candidateListPackage => candidateListPackage.plan._id === planId)

      if (!candidateListPackage) {
        throw new Error(`No candidateListPackage with planId ${planId} found`)
      }

      const candidate = candidateListPackage.candidates.find(candidate => candidate.clipboardFilePackage.fileNameWithoutExtension === fileNameWithoutExtension)

      if (!candidate) {
        throw new Error(`No candidate with fileNameWithoutExtension ${fileNameWithoutExtension} found`)
      }

      candidate.revision.comment = comment
    },

    FULL_RESET (state) {
      state.assignmentPackages = []
    },
  },

  getters: {
    hasPrintFiles: (state, getters, rootState, rootGetters) => {
      return rootGetters['clipboard/filePackagesUnassigned'].find(clipboardFilePackage => clipboardFilePackage.printFiles.length > 0)
    },

    relevantFileNames: (state, getters, rootState, rootGetters) => {
      if (getters.hasPrintFiles) {
        return rootGetters['clipboard/filePackagesUnassigned'].flatMap(clipboardFilePackage => clipboardFilePackage.printFiles.map(printFile => printFile.name))
      }

      return rootGetters['clipboard/filePackagesUnassigned'].flatMap(clipboardFilePackage => clipboardFilePackage.dwgFiles.map(dwgFile => dwgFile.name))
    },

    /** @returns {Array.<AssignmentPackage>} */
    assignmentPackagesPlanNameMatch: (state) => {
      return state.assignmentPackages
        .filter(assignmentPackage => {
          const hasSingleAssignment = assignmentPackage.candidatePackages.length === 1
          const hasSingleCandidate = assignmentPackage.candidatePackages.every(candidateListPackage => candidateListPackage.candidates.length === 1)
          const hasConflict = assignmentPackage.hasConflict
          const hasCandidatePlanNameMatch = assignmentPackage.candidatePackages.every(candidateListPackage => candidateListPackage.candidates.every(candidate => candidate.matchingDetails.doesMatchPlanNameExact))
          const hasFileMismatch = assignmentPackage.candidatePackages.find(candidateListPackage => candidateListPackage.candidates.find(candidate => candidate.matchingDetails.hasPrintFileMismatch || candidate.matchingDetails.hasDwgFileMismatch))

          return hasSingleAssignment && hasSingleCandidate && !hasConflict && hasCandidatePlanNameMatch && !hasFileMismatch
        })
        .sort(sortClipboardFileAssignmentsByNameAsc)
    },

    /** @returns {Array.<AssignmentPackage>} */
    assignmentPackagesBestGuess: (state) => {
      return state.assignmentPackages
        .filter(assignmentPackage => {
          const hasSingleAssignment = assignmentPackage.candidatePackages.length === 1
          const hasSingleCandidate = assignmentPackage.candidatePackages.every(candidateListPackage => candidateListPackage.candidates.length === 1)
          const hasConflict = assignmentPackage.hasConflict
          const hasCandidatePlanNameMatch = assignmentPackage.candidatePackages.every(candidateListPackage => candidateListPackage.candidates.every(candidate => candidate.matchingDetails.doesMatchPlanNameExact))
          const hasFileMismatch = assignmentPackage.candidatePackages.find(candidateListPackage => candidateListPackage.candidates.find(candidate => candidate.matchingDetails.hasPrintFileMismatch || candidate.matchingDetails.hasDwgFileMismatch))

          return hasSingleAssignment && hasSingleCandidate && !hasConflict && (!hasCandidatePlanNameMatch || hasFileMismatch)
        })
        .sort(sortClipboardFileAssignmentsByNameAsc)
    },

    /** @returns {Array.<AssignmentPackage>} */
    assignmentPackagesNoMatch: (state) => {
      return state.assignmentPackages
        .filter(assignmentPackage => {
          const hasSingleAssignment = assignmentPackage.candidatePackages.length === 1
          const hasSingleCandidate = assignmentPackage.candidatePackages.every(candidateListPackage => candidateListPackage.candidates.length === 1)
          const hasConflict = assignmentPackage.hasConflict

          return !hasSingleAssignment || !hasSingleCandidate || hasConflict
        })
        .sort((a, b) => {
          const aHasConflictMissingPlan = Boolean(a.candidatePackages.find(candidateListPackage => candidateListPackage.conflictDetails.hasConflictMissingPlan))
          const bHasConflictMissingPlan = Boolean(b.candidatePackages.find(candidateListPackage => candidateListPackage.conflictDetails.hasConflictMissingPlan))

          const aHasConflictOnlyPrintAllowed = Boolean(a.candidatePackages.find(candidateListPackage => candidateListPackage.conflictDetails.hasConflictOnlyPrintAllowed))
          const bHasConflictOnlyPrintAllowed = Boolean(b.candidatePackages.find(candidateListPackage => candidateListPackage.conflictDetails.hasConflictOnlyPrintAllowed))

          const aHasConflictWrongExtension = Boolean(a.candidatePackages.find(candidateListPackage => candidateListPackage.conflictDetails.hasConflictWrongExtension))
          const bHasConflictWrongExtension = Boolean(b.candidatePackages.find(candidateListPackage => candidateListPackage.conflictDetails.hasConflictWrongExtension))

          // 1st group: Files with extension that is no PRINT/CAD file
          // 2nd group: File packages with print file error (can't mix PRINT and CAD)
          // 3st group: Generally file packages without a match
          // 4th group: All matches

          if (aHasConflictWrongExtension !== bHasConflictWrongExtension) {
            return aHasConflictWrongExtension ? -1 : 1
          }

          if (aHasConflictOnlyPrintAllowed !== bHasConflictOnlyPrintAllowed) {
            return aHasConflictOnlyPrintAllowed ? -1 : 1
          }

          if (aHasConflictMissingPlan !== bHasConflictMissingPlan) {
            return aHasConflictMissingPlan ? -1 : 1
          }

          return 0
        })
    },
  },

  actions: {
    async fetch ({
      commit,
      state,
      rootState,
      getters,
      rootGetters,
      dispatch,
    }) {
      if (state.isLoading) {
        return
      }

      commit('SET_IS_LOADING', true)

      try {
        const [candidatesResponse] = await Promise.all([
          getFileAssignmentCandidates({
            axios: this.$axios,
            projectId: rootState.project.project._id,
            fileNames: getters.relevantFileNames,
            maxCandidates: 1,
          }),

          dispatch('plans/fetch', rootState.project.project._id, { root: true }), // A fresh plan list is needed to properly process the response of the file assignment candidates request
        ])

        const assignmentPackages = generateAssignmentPackages({
          candidatesResponse,
          clipboardFilePackages: rootGetters['clipboard/filePackagesUnassigned'],
          plans: rootState.plans.list,
          hasPrintFiles: getters.hasPrintFiles,
        })

        commit('SET_ASSIGNMENT_PACKAGES', assignmentPackages)
        commit('SET_IS_LOADING', false)
      } catch (err) {
        commit('SET_IS_LOADING', false)

        // The actual error handling is handled where the function is called
        throw err
      }
    },
  },

}

/** @returns {Array.<AssignmentPackage>} */
const generateAssignmentPackages = ({
  candidatesResponse,
  plans,
  hasPrintFiles,
  clipboardFilePackages,
}) => {
  const candidatesWithPlan = convertCandidatesResponse({
    candidatesResponse,
    clipboardFilePackages,
    plans,
    hasPrintFiles,
  })

  const clipboardFilePackagesWithoutPlanSuggestions = clipboardFilePackages.filter(pkg => {
    const doesPackageHaveSuggestion = candidatesWithPlan.find(candidates => {
      return candidates.candidates.find(candidate => candidate.clipboardFilePackage.fileNameWithoutExtension === pkg.fileNameWithoutExtension)
    })

    return !doesPackageHaveSuggestion
  })
  const candidatesWithoutPlan = generateCandidatesWithoutSuggestion({
    clipboardFilePackages: clipboardFilePackagesWithoutPlanSuggestions,
    hasPrintFiles,
  })

  const candidateList = [
    ...candidatesWithPlan,
    ...candidatesWithoutPlan,
  ]

  const assignmentPackages = []

  // Every candidatListEntry must be attached to a package. A package contains more than two entries, if multiple files matches the different plans
  for (const candidateListPackage of candidateList) {
    const existingPackage = assignmentPackages.find(assignmentPackage => {
      const existingCandidatePackage = assignmentPackage.candidatePackages.find(entry => {
        const conflictingPlanId = entry.candidates.find(candidate => candidate.conflictingPlanIds?.includes(candidateListPackage.plan?._id))

        return Boolean(conflictingPlanId)
      })

      return Boolean(existingCandidatePackage)
    })

    if (existingPackage) {
      existingPackage.candidatePackages.push(candidateListPackage)
      existingPackage.hasConflict = Boolean(existingPackage.candidatePackages.find(candidateListPackage => candidateListPackage.hasConflict))
    } else {
      assignmentPackages.push({
        _id: generateRandomId(),
        isSelected: false,
        hasConflict: candidateListPackage.hasConflict,
        candidatePackages: [candidateListPackage],
      })
    }
  }

  return assignmentPackages
}

/** @return {Array.<CandidatePackage>} */
function convertCandidatesResponse ({
  candidatesResponse,
  clipboardFilePackages,
  plans,
  hasPrintFiles,
}) {
  const plansWithCandidates = plans.filter(plan => candidatesResponse.find(candidatesResponseEntry => plan._id === candidatesResponseEntry.planId))

  return plansWithCandidates.map(plan => {
    const latestRevision = plan.revisions[plan.revisions.length - 1]
    const candidateResponseForPlan = candidatesResponse.find(candidateResponse => candidateResponse.planId === plan._id)
    const candidateFileNamesForPlan = candidateResponseForPlan.candidates.map(candidate => candidate.originalFileName)

    const candidates = candidateResponseForPlan.candidates.map(candidate => {
      const clipboardFilePackage = clipboardFilePackages.find(pkg => {
        const hasPrintFile = pkg.printFiles.find(printFile => printFile.nameDecoded === candidate.originalFileName)
        const hasDwgFile = pkg.dwgFiles.find(printFile => printFile.nameDecoded === candidate.originalFileName)

        return hasPrintFile || hasDwgFile
      })

      const conflictingPlanIds = candidatesResponse.filter(candidateResponse => {
        const isDifferentPlan = candidateResponse.planId !== plan._id
        const hasSameCandidate = candidateResponse.candidates.find(candidate => candidateFileNamesForPlan.includes(candidate.originalFileName))

        return isDifferentPlan && hasSameCandidate
      })
        .map(candidateResponse => candidateResponse.planId)

      const printFile = clipboardFilePackage.printFiles[0]
      const dwgFile = clipboardFilePackage.dwgFiles[0]

      return {
        clipboardFilePackage,
        revision: {
          index: '',
          pdf_file: printFile
            ? {
                filename: decodeURIComponent(printFile.name),
                filesize: printFile.size,
                key: printFile.s3Key,
              }
            : {},
          dwg_file: dwgFile
            ? {
                filename: decodeURIComponent(dwgFile.name),
                filesize: dwgFile.size,
                key: dwgFile.s3Key,
              }
            : {},
        },
        matchingDetails: {
          doesMatchPlanNameExact: candidate.doesMatchPlanNameExact,
          doesMatchPlanNameCleaned: candidate.doesMatchPlanNameCleaned,
          hasPrintFileMismatch: !printFile && latestRevision.pdf_file?.filename,
          hasDwgFileMismatch: !dwgFile && latestRevision.dwg_file?.filename,
          score: candidate.score,
        },
        conflictingPlanIds,
      }
    })

    const hasConflictMultipleCandidates = candidates.length > 1
    const hasConflictMultiplePrintFiles = Boolean(candidates.find(candidate => candidate.clipboardFilePackage.printFiles.length > 1))
    const hasConflictMultipleDwgFiles = Boolean(candidates.find(candidate => candidate.clipboardFilePackage.dwgFiles.length > 1))
    const hasConflictingPlanIds = Boolean(candidates.find(candidate => candidate.conflictingPlanIds.length > 0))
    const hasConflictOnlyPrintAllowed = Boolean(hasPrintFiles && candidates.find(candidate => candidate.clipboardFilePackage.printFiles.length === 0 && candidate.clipboardFilePackage.dwgFiles.length === 0))

    return {
      plan,
      hasConflict: hasConflictMultiplePrintFiles || hasConflictMultipleDwgFiles || hasConflictMultipleCandidates || hasConflictingPlanIds,
      conflictDetails: {
        hasConflictMultipleCandidates,
        hasConflictMultiplePrintFiles,
        hasConflictMultipleDwgFiles,
        hasConflictingPlanIds,
        hasConflictOnlyPrintAllowed,
        hasConflictWrongExtension: false,
        hasConflictMissingPlan: false,
      },
      candidates,
    }
  })
    .filter(Boolean)
}

/** @return {Array.<CandidatePackage>} */
function generateCandidatesWithoutSuggestion ({
  clipboardFilePackages,
  hasPrintFiles,
}) {
  return clipboardFilePackages.map(clipboardFilePackage => {
    const printFile = clipboardFilePackage.printFiles[0]
    const dwgFile = clipboardFilePackage.dwgFiles[0]

    const validPlanExtensions = [
      ...constants.VALID_PRINT_FILE_EXTENSIONS,
      ...constants.VALID_CAD_FILE_EXTENSIONS,
    ]
    /* This error should only appear if there is no suggestion. While generating the suggestions (on the server) we don't care about the extension.
     * If we find a match, although the extension is not really a PRINT or CAD file, we still show the match.
     * Only if NO match was found, we show an error, that the extension is not valid
    */
    const hasConflictWrongExtension = !printFile && dwgFile && !validPlanExtensions.includes(dwgFile.extension.toLowerCase())

    const hasConflictOnlyPrintAllowed = hasPrintFiles && !hasConflictWrongExtension ? !printFile && dwgFile : false

    return {
      hasConflict: true,
      conflictDetails: {
        hasConflictMultipleCandidates: false,
        hasConflictMultiplePrintFiles: clipboardFilePackage.printFiles.length > 1,
        hasConflictMultipleDwgFiles: clipboardFilePackage.dwgFiles.length > 1,
        hasConflictingPlanIds: false,
        hasConflictOnlyPrintAllowed,
        hasConflictWrongExtension,
        hasConflictMissingPlan: !hasConflictOnlyPrintAllowed && !hasConflictWrongExtension,
      },
      candidates: [{
        clipboardFilePackage,
        matchingDetails: {
          doesMatchPlanNameExact: false,
          doesMatchPlanNameCleaned: false,
          hasPrintFileMismatch: false,
          hasDwgFileMismatch: false,
          score: undefined,
        },
        revision: {
          index: '',
          pdf_file: printFile
            ? {
                filename: decodeURIComponent(printFile.name),
                filesize: printFile.size,
                key: printFile.s3Key,
              }
            : {},
          dwg_file: dwgFile
            ? {
                filename: decodeURIComponent(dwgFile.name),
                filesize: dwgFile.size,
                key: dwgFile.s3Key,
              }
            : {},
        },
      }],
    }
  })
}
