<template>
  <div
    class="highlight-background"
    data-testid="modal-darkener"
    tabindex="0"
  />
</template>

<script>
export default {
}
</script>
