import LocationLayer from '@client-shared/models/location-layer.model'
import Task from '@client-shared/models/task.model'
import { io } from 'socket.io-client'

export default {
  install: (app) => {
    let ioConnection
    let isConnected = false

    const socket = {
      connect (projectId) {
        if (isConnected || app.config.globalProperties.$config.isBackupApp) {
          return
        }

        if (!projectId) {
          console.error('No projectId passed to connect to websocket')
          return
        }

        ioConnection = io.connect(app.config.globalProperties.$config.api.websocketServerUrl, {
          transports: ['websocket'],
          auth: {
            token: app.config.globalProperties.$auth.accessToken,
            projectId,
          },
        })

        ioConnection.on('connect', () => {
          isConnected = true
        })

        ioConnection.on('disconnect', () => {
          isConnected = false
        })

        ioConnection.on('TRIGGER_RELOAD_LOCATION_LAYERS', (payload) => {
          const { projectId } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TRIGGER_RELOAD_LOCATION_LAYERS event does not match with currently loaded project')
          }

          store.dispatch('locationLayers/fetch', { projectId })
        })

        ioConnection.on('LOCATION_LAYER_UPDATE', (payload) => {
          const {
            projectId,
            locationLayer,
          } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of LOCATION_LAYER_UPDATE event does not match with currently loaded project')
          }

          const originalLocationLayer = store.getters['locationLayers/getById'](locationLayer._id)

          if (!originalLocationLayer) {
            console.error(`Original locationLayer with _id "${locationLayer._id}" couldn't be found`)
            return
          }

          store.commit('locationLayers/REPLACE_LOCATION_LAYER', new LocationLayer(locationLayer))
        })

        ioConnection.on('TASK_FILE_THUMBNAIL_UPDATE', (payload) => {
          const {
            projectId,
            taskId,
            task,
          } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_FILE_THUMBNAIL_UPDATE event does not match with currently loaded project')
          }

          const originalTask = store.getters['tasks/getById'](taskId)

          if (!originalTask) {
            console.error(`Original task with _id "${taskId}" couldn't be found`)
            return
          }

          store.commit('tasks/REPLACE_TASK', new Task(task))
        })

        ioConnection.on('TASK_COMMENT_FILE_THUMBNAIL_UPDATE', (payload) => {
          const {
            projectId,
            taskId,
            task,
          } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_COMMENT_FILE_THUMBNAIL_UPDATE event does not match with currently loaded project')
          }

          const originalTask = store.getters['tasks/getById'](taskId)

          if (!originalTask) {
            console.error(`Original task with _id "${taskId}" couldn't be found`)
            return
          }

          store.commit('tasks/REPLACE_TASK', new Task(task))
        })

        ioConnection.on('TASK_COMMENT_NEW', (payload) => {
          const { projectId, task } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_COMMENT_NEW event does not match with currently loaded project')
          }

          store.commit('tasks/REPLACE_TASK', new Task(task))
        })
      },

      disconnect () {
        if (!isConnected) {
          return
        }

        ioConnection.disconnect()
      },

      getConnection () {
        return ioConnection
      },
    }

    app.config.globalProperties.$socket = socket
  },
}
