<template>
  <div
    class="com_selection-box__item"
    :class="{
      'is_single-selected': isSelected
    }"
  >
    <div
      class="com_selection-box__item-container"
      @click="$emit('click:option')"
    >
      <span class="com_selection-box__radio-check-box" />

      <span
        class="com_selection-box__label"
      >
        <slot />
      </span>
    </div>
    <slot
      v-if="isSelected"
      name="selectionContent"
    />
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'click:option',
  ],
}
</script>
