import { fetchRefreshToken } from '@client-shared/api/refresh-token.api'
import isOnLocalhost from '@client-shared/utils/is-on-localhost'
import dayjs from 'dayjs'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'universal-cookie'
import { reactive, computed } from 'vue'

import i18nInstance from '@/plugins/i18n'

const accessTokenKey = 'auth._token.local'
const refreshTokenKey = 'auth._refresh_token.local'

const cookies = new Cookies()
const $i18n = i18nInstance.global

const getAccessToken = (key) => {
  // get it from cookie first
  let token = cookies.get(key)

  // legacy fallback - get existing cookie from localStorage
  if (!token) {
    token = window.localStorage.getItem(key)
  }

  try {
    // ensure token format is valid by trying to decoding it
    jwtDecode(token)
    return token
  } catch {}
}

const setAuthCookie = ({
  key,
  token,
}) => {
  cookies.set(key, token, {
    path: '/',
    expires: dayjs().add(1, 'year').toDate(),
    secure: !isOnLocalhost,
    sameSite: isOnLocalhost ? 'lax' : 'strict',
  })
}

const removeAuthCookie = ({
  key,
}) => {
  cookies.remove(key, {
    path: '/',
    secure: !isOnLocalhost,
    sameSite: isOnLocalhost ? 'lax' : 'strict',
  })
}

export default {
  install: (app) => {
    const $axios = app.config.globalProperties.$axios
    const $store = app.config.globalProperties.$store
    const $router = app.config.globalProperties.$router

    const auth = reactive({
      loggedIn: computed(() => Boolean(auth.user)),
      accessToken: getAccessToken(accessTokenKey),
      refreshToken: getAccessToken(refreshTokenKey),
      user: undefined,

      login: async function ({ email, password, totp }) {
        const { data } = await $axios.post('login', {
          email,
          password,
          totp,
        },
        {
          headers: {
            Authorization: null,
          },
        })

        auth.setAccessToken(data.access_token)
        auth.setRefreshToken(data.refresh_token)

        if (auth.accessToken) {
          await auth.fetchUser()
        }

        return data
      },

      fetchUser: async function () {
        try {
          const { data } = await $axios.get('current-user')

          auth.setUser(data.user)
        } catch (err) {}
      },

      setUser: function (user) {
        auth.user = user
        $store.commit('currentUser/SET_USER', user)

        if (!user) {
          // set anonymous raygun user identifier
          if (window.rg4js) {
            window.rg4js('endSession')
            window.rg4js('setUser', {
              isAnonymous: true,
            })
          }
        } else if (user?._id) {
          // set anonymous raygun user identifier
          if (window.rg4js) {
            window.rg4js('setUser', {
              identifier: user._id,
              isAnonymous: false,
            })
          }
        }
      },

      setAccessToken: function (token) {
        auth.accessToken = token

        if (!token) {
          removeAuthCookie({ key: accessTokenKey })
          window.localStorage.removeItem(accessTokenKey)
          return
        }

        setAuthCookie({
          key: accessTokenKey,
          token,
        })
        window.localStorage.setItem(accessTokenKey, token)
      },

      setRefreshToken: function (token) {
        auth.refreshToken = token

        if (!token) {
          removeAuthCookie({ key: refreshTokenKey })
          window.localStorage.removeItem(refreshTokenKey)
          return
        }

        setAuthCookie({
          key: refreshTokenKey,
          token,
        })
        window.localStorage.setItem(refreshTokenKey, token)
      },

      getNewRefreshToken: async function () {
        const { data: authResult } = await fetchRefreshToken({
          $axios,
          token: auth.refreshToken,
        })

        if (!authResult) {
          return
        }

        auth.setAccessToken(authResult.access_token)
        auth.setRefreshToken(authResult.refresh_token)
      },

      logout: function ({ shouldBroadcast = true, redirectTarget } = {}) {
        const $broadcastChannel = app.config.globalProperties.$broadcastChannel
        const $config = app.config.globalProperties.$config

        const email = auth.user?.email

        auth.setAccessToken(undefined)
        auth.setRefreshToken(undefined)
        auth.setUser(undefined)

        if (shouldBroadcast) {
          $broadcastChannel.postMessage($config.constants.BROADCAST_MESSAGES.LOGOUT)
        }

        if ($config.isDemoserver && !window.Cypress) {
          window.location.href = $i18n.t('data.pf_links.planfred_website')
          return
        }

        if (redirectTarget) {
          return $router.push(redirectTarget)
        }

        return $router.push({
          name: 'login',
          query: {
            redirect: window.location.pathname,
            sessionExpired: true,
            email,
          },
        })
      },
    })

    $router.beforeEach(async (to, from) => {
      if (!auth.loggedIn) {
        await auth.fetchUser()
      }

      if (!to.meta.isPublic && !auth.loggedIn) {
        return $router.push({
          name: 'login',
          query: {
            redirect: to.fullPath,
            sessionExpired: true,
          },
        })
      }

      if (to.meta.isPublic && auth.loggedIn && !to.meta.canVisitAsLoggedIn) {
        return $router.push(to.query.redirect || { name: 'projects' })
      }
    })

    app.config.globalProperties.$auth = auth
  },
}
