import { baseConfig } from '../_config-base.js'

export default {
  ...baseConfig,
  api: {
    ...baseConfig.api,
    // host: `https://app.staabserver.com/${baseConfig.api.apiversion}`, // we use netlify proxy for /api requests
    host: `https://api.app.staabserver.com/${baseConfig.api.apiversion}`,
    companionUrl: `https://api.app.staabserver.com/${baseConfig.api.apiversion}`, // we use CORS for uppy companion calls
    websocketServerUrl: 'https://api.app.staabserver.com',
  },
  awsS3BucketHost: 'https://staabserver.s3-accelerate.amazonaws.com',
  isStaabServer: true,
  logoPath: new URLsrc/assets/img/customer-theme/staabservercom/staabserver_logo.svg', import.meta.url).href,
  tasksBetaUsers: [ // If an array entry matches with the logged in users email (regex), a user can see the tasks tab
  ],
  customerFeatures: {
    ...baseConfig.customerFeatures,
    htmlTagClass: 'theme theme--staabservercom',
  },
}
