<template>
  <transition
    name="totop-fade"
  >
    <a
      v-if="show"
      class="go-to-top"
      @click="goToTop()"
    >
      <PfIcon
        name="chevronUp"
        size="lg"
      />
      <span
        class="go-to-top__text"
      >
        {{ $t('layout.go_to_top') }}
      </span>
    </a>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      show: false,
    }
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },

  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll () {
      if (window.scrollY > 50 || window.pageYOffset > 50) {
        this.show = true
        return
      }

      this.show = false
    },

    goToTop () {
      this.$scrollToTop()
    },
  },
}
</script>
