import axios from 'axios'

import authHandler from './axios-interceptors/auth-handler.js'
import isBackupAppInterceptor from './axios-interceptors/backup-app.js'
import clientVersionMismatchInterceptor from './axios-interceptors/client-version-mismatch.js'
import delayResponseInterceptor from './axios-interceptors/delay-response.js'
import errorHandler from './axios-interceptors/error-handler.js'
import setCacheExpiry from './axios-interceptors/set-cache-expiry.js'
import setHeaders from './axios-interceptors/set-headers.js'
import setLanguageFromCurrentUser from './axios-interceptors/set-language-from-current-user.js'

export default {
  install: (app) => {
    const $config = app.config.globalProperties.$config

    axios.defaults.baseURL = $config.api.host

    // offline mode - backup app interceptor
    if ($config.isBackupApp) {
      isBackupAppInterceptor({ $axios: axios })
    } else {
      setHeaders({ $axios: axios, app })
      setCacheExpiry({ $axios: axios, app })
      authHandler({ $axios: axios, app })
    }

    clientVersionMismatchInterceptor({ $axios: axios, packageVersion: $config.packageVersion })
    delayResponseInterceptor({ $axios: axios, DELAY_MS: 2000, $config })
    setLanguageFromCurrentUser({ $axios: axios, app })
    errorHandler({ $axios: axios, app })

    app.config.globalProperties.$axios = axios
    app.config.globalProperties.$store.$axios = axios
  },
}
