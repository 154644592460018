import ClipboardFile from '@client-shared/models/clipboard-file.model.js'
import generateRandomId from '@client-shared/utils/generate-random-id.js'

import constants from '@/config/constants.js'

export default {
  namespaced: true,

  state: () => {
    return {
      isOpen: false,
      files: [],
      filter: '',
      storeId: generateRandomId(),

      sortSettings: {
        sortProperty: constants.SORT_PROPERTIES.CLIPBOARD_FILE_NAME,
        sortOrderReversed: false,
      },
    }
  },

  getters: {
    filesUnassigned: (state, getters, rootState) => {
      return state.files.filter(f => f.isUnassigned)
    },

    filePackagesUnassigned: (state, getters) => {
      const packages = []
      const relevantClipboardFiles = getters.filesUnassigned
      const uniqueFilesWithoutExtension = relevantClipboardFiles.map(file => file.name.replace(file.extension, ''))
      const uniqueFileNamesWithoutExtension = [...new Set([...uniqueFilesWithoutExtension])]

      for (const fileNameWithoutExtension of uniqueFileNamesWithoutExtension) {
        const clipboardFiles = relevantClipboardFiles.filter(clipboardFile => clipboardFile.name.replace(clipboardFile.extension, '') === fileNameWithoutExtension)
        const clipboardFilesPrint = clipboardFiles.filter(clipboardFile => clipboardFile.isPrintFile)
        const clipboardFilesDwg = clipboardFiles.filter(clipboardFile => !clipboardFile.isPrintFile)
        const existingPackage = packages.find(pkg => pkg.fileNameWithoutExtension === fileNameWithoutExtension)

        if (existingPackage) {
          existingPackage.printFiles.push(...clipboardFilesPrint)
          existingPackage.dwgFiles.push(...clipboardFilesDwg)
        } else {
          packages.push({
            fileNameWithoutExtension,
            printFiles: clipboardFilesPrint,
            dwgFiles: clipboardFilesDwg,
          })
        }
      }

      return packages
    },

    filesAssigned: (state, getters, rootState) => {
      return state.files.filter(f => f.assignedAs || f.assignedAsTmp)
    },

    filesUploading: (state, getters) => {
      return state.files.filter(f => f.isUploadRunning)
    },

    getFileByName: (state, getters) => {
      return fileName => state.files.find(f => f.name === fileName)
    },

    getByFilter: (state, getters) => {
      if (state.filter === 'UNASSIGNED') { return getters.filesUnassigned }
      if (state.filter === 'ASSIGNED') { return getters.filesAssigned }
      if (state.filter === 'UPLOADING') { return getters.filesUploading }

      return state.files
    },

    hasRunningUploadsOverall: (state) => {
      return state.files.some(f => f.isUploadRunning)
    },
  },

  mutations: {
    TOGGLE_IS_OPEN (state) {
      state.isOpen = !state.isOpen
    },

    SET_IS_OPEN (state, isOpen) {
      state.isOpen = isOpen
    },

    RESET_IS_OPEN (state) {
      state.isOpen = false
    },

    ADD_FILE (state, file) {
      if (!(file instanceof ClipboardFile)) {
        throw new TypeError('clipbard/ADD_FILE mutation only allows ClipboardFile instances')
      }

      state.files.push(new ClipboardFile({ ...file }))
    },

    REPLACE_FILES (state, files) {
      if (!files.every(f => f instanceof ClipboardFile)) {
        throw new TypeError('clipbard/REPLACE_FILES mutation only allows ClipboardFile instances')
      }

      state.files = [...files]
    },

    REPLACE_FILE (state, file) {
      if (!(file instanceof ClipboardFile)) {
        throw new TypeError('clipbard/REPLACE_FILE mutation only allows ClipboardFile instances')
      }

      state.files = [...state.files].map(f => {
        if (f.id === file.id) {
          return new ClipboardFile({ ...file })
        }

        return f
      })
    },

    CLOSE (state) {
      state.isOpen = false
    },

    FULL_RESET (state) {
      state.isOpen = false
      state.files = []
      state.filter = ''
      state.sortSettings = {
        sortProperty: constants.SORT_PROPERTIES.CLIPBOARD_FILE_NAME,
        sortOrderReversed: false,
      }
    },

    REMOVE_FILE (state, file) {
      state.files = state.files.filter(f => f.id !== file.id)
    },

    REMOVE_FILES (state, filesToRemove) {
      state.files = state.files.filter(file => {
        const shouldRemoveFile = filesToRemove.find(fileToRemove => fileToRemove.id === file.id)

        return !shouldRemoveFile
      })
    },

    SET_SORT_PROPERTY (state, sortProperty) {
      state.sortSettings.sortProperty = sortProperty
    },

    SET_SORT_ORDER_REVERSED (state, sortOrderReversed) {
      state.sortSettings.sortOrderReversed = sortOrderReversed
    },

    SET_FILE_ASSIGNED (state, { s3Key, assignedAs }) {
      state.files = state.files.map(f => {
        if (f.s3Key === s3Key) {
          return new ClipboardFile({
            ...f,
            assignedAs,
          })
        }

        return f
      })
    },

    SET_FILES_ASSIGNED (state, { s3Keys, assignedAs }) {
      state.files = state.files.map(f => {
        if (s3Keys.includes(f.s3Key)) {
          return new ClipboardFile({
            ...f,
            assignedAs,
          })
        }

        return f
      })
    },

    SET_FILE_ASSIGNED_TMP (state, { s3Key, assignedAsTmp }) {
      state.files = state.files.map(f => {
        if (f.s3Key === s3Key) {
          return new ClipboardFile({
            ...f,
            assignedAsTmp,
            assignedAsTmpStoreId: state.storeId,
          })
        }

        return f
      })
    },

    RESET_ASSIGNED_TMP (state) {
      state.files = state.files.map(f => {
        if (f.assignedAsTmpStoreId === state.storeId) {
          return new ClipboardFile({
            ...f,
            assignedAsTmp: null,
            assignedAsTmpStoreId: null,
          })
        }

        return f
      })
    },

    RESET_ASSIGNED_TMP_SINGLE_FILE (state, fileId) {
      state.files = state.files.map(f => {
        if (f.assignedAsTmpStoreId === state.storeId && f.id === fileId) {
          return new ClipboardFile({
            ...f,
            assignedAsTmp: null,
            assignedAsTmpStoreId: null,
          })
        }

        return f
      })
    },

    SET_FILTER (state, filter) {
      const validFilters = [
        '',
        'ASSIGNED',
        'UNASSIGNED',
        'UPLOADING',
      ]

      if (!validFilters.includes(filter)) {
        console.error(`${filter} is not a valid clipboard filter`)
        return
      }
      state.filter = filter
    },
  },
}
