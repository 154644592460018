import { baseConfig } from '../_config-base.js'

export default {
  ...baseConfig,
  showGoToWebsiteLink: true,
  api: {
    ...baseConfig.api,
    // host: `https://demo.planfred.com/${baseConfig.api.apiversion}`, // we use netlify proxy for /api requests
    host: `https://api.demo.planfred.com/${baseConfig.api.apiversion}`,
    companionUrl: `https://api.demo.planfred.com/${baseConfig.api.apiversion}`, // we use CORS for uppy companion calls
    websocketServerUrl: 'https://api.demo.planfred.com',
  },
  awsS3BucketHost: 'https://planfreddemoserver.s3-accelerate.amazonaws.com',
  isDemoserver: true,
  logoPath: new URLsrc/assets/img/customer-theme/demoplanfredcom/demoplanfredcom_logo.svg', import.meta.url).href,
  tasksBetaUsers: [ // If an array entry matches with the logged in users email (regex), a user can see the tasks tab
  ],
  customerFeatures: {
    ...baseConfig.customerFeatures,
    htmlTagClass: 'theme theme--demoplanfredcom',
    hideFeatureAlerts: false,
  },
  demoUserEmail: 'arch@planmanager.at',
  demoUserPassword: 'welcome',
  demoProjectID: '52f4b19e0385b300006905ad',
  demoProjectIDEnglish: '5e2816b102449200047b7c11',
  msToShowDemoserverModal: 2 * 60 * 1000, // 2 min
}
