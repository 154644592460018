import constants from '@/config/constants'

export default {
  namespaced: true,

  state: () => {
    return {
      selectedProperty: 'top_category',
    }
  },

  mutations: {
    SET_SELECTED_PROPERTY (state, property) {
      const validProperties = Object.values(constants.MULTI_ACTION)

      if (!validProperties.includes(property)) {
        throw new Error('INVALID_PROPERTY_SET')
      }

      state.selectedProperty = property
    },
  },
}
