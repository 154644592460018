<template>
  <PfModal
    :show-close="false"
    class="com_modal--sm com_modal--no-header"
    data-testid="two-factor-auth-disabled-modal"
  >
    <div
      class="recovery-message"
    >
      <div
        class="recovery-message__title"
      >
        {{ $t('feature.components.modal_two_factor_auth_disabled.is_not_active') }}
      </div>

      <PfButtonBar
        class="recovery-message__button-bar comv2_button-bar--align-center"
      >
        <PfButtonBarButton
          data-testid="reactivate-two-factor-auth"
          @click="onClickReactivate"
        >
          {{ $t('feature.components.modal_two_factor_auth_disabled.reactivate') }}
        </PfButtonBarButton>

        <PfButtonBarLink
          data-testid="keep-two-factor-auth-disabled"
          @click="onClickDisable"
        >
          {{ $t('feature.components.modal_two_factor_auth_disabled.continue_without') }}
        </PfButtonBarLink>

        <template #error>
          <MessageBoxError
            v-if="state === validStates.SUBMIT_ERROR"
            :message="$t('feature.components.modal_two_factor_auth_disabled.server_error')"
          />
        </template>
      </PfButtonBar>
    </div>
  </PfModal>
</template>

<script>
import { disableTwoFactorAuth } from '@client-shared/api/users.api.js'

import MessageBoxError from '@/components/MessageBoxError.vue'
import PfButtonBar from '@/components/PfButtonBar.vue'
import PfButtonBarButton from '@/components/PfButtonBarButton.vue'
import PfButtonBarLink from '@/components/PfButtonBarLink.vue'
import PfModal from '@/components/PfModal.vue'

export default {
  components: {
    PfButtonBarLink,
    PfButtonBarButton,
    PfButtonBar,
    PfModal,
    MessageBoxError,
  },

  data () {
    const validStates = Object.freeze({
      INITIAL: 1,
      SUBMIT_PENDING: 2,
      SUBMIT_ERROR: 3,
    })

    return {
      validStates,
      state: validStates.INITIAL,
    }
  },

  methods: {
    async onClickReactivate () {
      try {
        await disableTwoFactorAuth({
          axios: this.$axios,
          user: this.$auth.user,
        })

        this.$router.push({
          name: 'account',
          query: {
            'enable-2fa': 'true',
          },
        })
      } catch {
        this.state = this.validStates.SUBMIT_ERROR
      }
    },

    async onClickDisable () {
      try {
        await disableTwoFactorAuth({
          axios: this.$axios,
          user: this.$auth.user,
        })

        this.$auth.fetchUser()
      } catch {
        this.state = this.validStates.SUBMIT_ERROR
      }
    },
  },
}
</script>
