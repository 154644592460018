export default {
  namespaced: true,

  state: () => {
    return {
      isOpen: false,
    }
  },

  mutations: {
    TOGGLE_IS_OPEN (state) {
      state.isOpen = !state.isOpen
    },

    SET_IS_OPEN (state, isOpen) {
      state.isOpen = isOpen
    },
  },
}
