import constants from '@client-shared/config/constants'

import datePickerRanges from '../utils/datepicker-ranges'

export default {
  namespaced: true,

  state: () => {
    return {
      categories: [],
      participantRoles: [],
      participantUserIds: [],
      dateRange: constants.VALID_ACTIVITY_FILTER_DATE_RANGE_LABELS.DAYS_30,
      startDate: null,
      endDate: null,

      lastLocalUpdate: null,
      lastServerUpdate: null,
    }
  },

  getters: {
    isFilterActive: (state, getters, rootState) => {
      return state.categories.length > 0 ||
       state.participantRoles.length > 0 ||
       state.participantUserIds.length > 0 ||
       state.startDate || state.endDate ||
       (state.dateRange !== constants.VALID_ACTIVITY_FILTER_DATE_RANGE_LABELS.DAYS_30)
    },

    getDateRange: (state, getters, rootState) => {
      for (const dateRange of datePickerRanges) {
        if (dateRange.label === state.dateRange) {
          return dateRange.get(rootState.project.project)
        }
      }

      // return custom date
      return {
        start: state.startDate,
        end: state.endDate,
      }
    },

  },

  mutations: {
    SET_FILTERS (state, { filters = {}, lastServerUpdate }) {
      state.categories = filters.categories || []
      state.participantRoles = filters.participantRoles || []
      state.participantUserIds = filters.participantUserIds || []
      state.dateRange = filters.dateRange
      state.startDate = filters.startDate
      state.endDate = filters.endDate

      if (lastServerUpdate) {
        state.lastLocalUpdate = null
        state.lastServerUpdate = new Date(lastServerUpdate)
      } else {
        state.lastLocalUpdate = new Date()
        state.lastServerUpdate = null
      }
    },

    RESET_FILTERS (state) {
      state.categories = []
      state.participantRoles = []
      state.participantUserIds = []
      state.dateRange = constants.VALID_ACTIVITY_FILTER_DATE_RANGE_LABELS.DAYS_30
      state.startDate = null
      state.endDate = null

      state.lastLocalUpdate = new Date()
      state.lastServerUpdate = null
    },

    ADD_CATEGORY_FILTER (state, filterToAdd) {
      state.categories = [
        ...state.categories,
        filterToAdd,
      ]
      state.lastLocalUpdate = new Date()
    },

    REMOVE_CATEGORY_FILTER (state, filterToRemove) {
      state.categories = [...state.categories.filter(filter => filter !== filterToRemove)]
      state.lastLocalUpdate = new Date()
    },

    ADD_PARTICIPANT_ROLE_FILTER (state, filterToAdd) {
      state.participantRoles = [
        ...state.participantRoles,
        filterToAdd,
      ]
      state.lastLocalUpdate = new Date()
    },

    ADD_PARTICIPANT_USER_IDS_FILTER (state, filterToAdd) {
      state.participantUserIds = [
        ...state.participantUserIds,
        filterToAdd,
      ]
      state.lastLocalUpdate = new Date()
    },

    SET_DATE_RANGE (state, dateRange) {
      state.dateRange = dateRange
      state.lastLocalUpdate = new Date()
    },

    SET_START_DATE (state, date) {
      state.startDate = date
      state.lastLocalUpdate = new Date()
    },

    SET_END_DATE (state, date) {
      state.endDate = date
      state.lastLocalUpdate = new Date()
    },

    SET_PARTICIPANT_USER_IDS (state, participantUserIds) {
      state.participantUserIds = [...participantUserIds]
      state.lastLocalUpdate = new Date()
    },

    SET_PARTICIPANT_ROLES (state, participantRoles) {
      state.participantRoles = [...participantRoles]
      state.lastLocalUpdate = new Date()
    },

    REMOVE_PARTICIPANT_USER_IDS_FILTER (state, participantUserIdToRemove) {
      state.participantUserIds = [...state.participantUserIds.filter(participantUserId => participantUserId !== participantUserIdToRemove)]
      state.lastLocalUpdate = new Date()
    },

    REMOVE_PARTICIPANT_ROLES_FILTER (state, participantRoleToRemove) {
      state.participantRoles = [...state.participantRoles.filter(participantRole => participantRole !== participantRoleToRemove)]
      state.lastLocalUpdate = new Date()
    },

    FULL_RESET (state) {
      state.categories = []
      state.participantRoles = []
      state.participantUserIds = []
      state.dateRange = constants.VALID_ACTIVITY_FILTER_DATE_RANGE_LABELS.DAYS_30
      state.startDate = null
      state.endDate = null

      state.lastLocalUpdate = null
      state.lastServerUpdate = null
    },
  },
}
