<template>
  <div class="page__footer">
    <div class="page__footer-left">
      <span
        v-if="$config.showBuildDateInFooter && !$config.isBackupApp"
        data-testid="build-date"
      >
        {{ $dayjs(buildDate).locale($i18n.locale).format('lll') }} -
      </span>

      {{ versionNumber }} -
      <a
        href="/changelog"
        target="_blank"
      >{{ $t('page_titles.changelog') }}</a> |
      <LanguageSwitch
        data-testid="language-switch-footer"
        class="com_language-menu--footer"
      />
    </div>

    <div class="page__footer-right">
      <i18n-t
        scope="global"
        keypath="layout.footer.visit_us_at"
      >
        <template #domain>
          <a
            :href="$t('data.pf_links.planfred_website')"
            data-testid="planfred-website-link-footer"
          >
            www.planfred.com
          </a>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script>
import packageJson from '../../package.json'

import LanguageSwitch from '@/components/LanguageSwitch.vue'

export default {
  components: {
    LanguageSwitch,
  },

  computed: {
    versionNumber () {
      return packageJson.version
    },

    buildDate () {
      return packageJson.buildDate
    },
  },
}
</script>
