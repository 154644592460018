import { baseConfig } from '../_config-base.js'

export default {
  ...baseConfig,
  showGoToWebsiteLink: true,
  showBuildDateInFooter: true,
  stripeApiKeyPublic: 'pk_test_51Ipq5yAXnj3HeKyrZADDyvM1LFCRczJzbg7yZ8SHDyuJLJLVXrxlR9eHuVRxxBpvmAPAWJSlT9fIYS83ziIO4OPX00wycwc6Tt',
  api: {
    ...baseConfig.api,
    // host: `https://app.planfred.com.planfredapp.com/${baseConfig.api.apiversion}`, // we use netlify proxy for /api requests
    host: `https://api.app.planfred.com.planfredapp.com/${baseConfig.api.apiversion}`,
    companionUrl: `https://api.app.planfred.com.planfredapp.com/${baseConfig.api.apiversion}`, // we use CORS for uppy companion calls
    websocketServerUrl: 'https://api.app.planfred.com.planfredapp.com',
  },
  awsS3BucketHost: 'https://planfreddevelopmentserver.s3-accelerate.amazonaws.com',
  tasksBetaUsers: [ // If an array entry matches with the logged in users email (regex), a user can see the tasks tab
    /.*/,
  ],
  customerFeatures: {
    ...baseConfig.customerFeatures,
    enableAgbPrivacyConfirmation: true,
    disableUserRegistrationForm: false,
    hideFeatureAlerts: false,
    account: {
      ...baseConfig.customerFeatures.account,
      disablePayment: false,
      disableDelete: false,
      disableUsedStorage: false,
    },
  },
}
