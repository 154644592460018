export const confirmAgbDsgvo = async ({ axios, userId }) => {
  const patchObject = {
    op: 'update',
    path: 'confirmedAgbDsgvo',
    value: {
      old: false,
      new: true,
    },
  }

  const { data } = await axios.patch(`users/${userId}`, patchObject)
  return data
}

export const updateProfile = async ({ axios, user, newProfile }) => {
  const patchObject = {
    op: 'update',
    path: 'profile',
    value: {
      old: user.profile,
      new: newProfile,
    },
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const updateSignature = async ({ axios, user, newSignature }) => {
  const patchObject = {
    op: 'update',
    path: 'signature',
    value: {
      old: user.profile.signature,
      new: newSignature,
    },
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const updatePassword = async ({ axios, user, password, oldPassword }) => {
  const postObject = {
    email: user.email,
    password,
    oldPassword,
  }

  const { data } = await axios.post('userregistrations/updatepassword', postObject)
  return data
}

export const updatePayment = async ({ axios, user, newPayment }) => {
  const patchObject = {
    op: 'update',
    path: 'payment',
    value: {
      old: user.payment,
      new: newPayment,
    },
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const executeCancelPlan = async ({ axios, user, info, cancelPlan, message }) => {
  const patchObject = {
    op: 'execute',
    path: 'cancelPlan',
    value: {
      new: cancelPlan,
    },
    info,
    message,
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const updateCrmFeatureFlag = async ({ axios, user, propertyName, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'updateCrmFeatureFlag',
    value: {
      old: {
        [propertyName]: user.crm[propertyName],
      },
      new: {
        [propertyName]: newValue,
      },
    },
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const updateLanguageSelected = async ({ axios, user, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'languageSelected',
    value: {
      old: user.languageSelected,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const sendSupportMail = async ({ axios, info, topic, text }) => {
  const body = {
    info,
    topic,
    text,
  }

  const { data } = await axios.post('users/mail/support', body)
  return data
}

export const deleteAccount = async ({ axios, user }) => {
  const body = {
    user,
  }
  return await axios.delete(`users/${user._id}`, body)
}

export const disableTwoFactorAuth = async ({ axios, user, password }) => {
  const patchObject = {
    op: 'execute',
    path: 'disableTwoFactorAuth',
    value: {
      new: password,
    },
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const requestTwoFactorAuth = async ({ axios, user }) => {
  const patchObject = {
    op: 'execute',
    path: 'requestTwoFactorAuth',
    value: {},
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const confirmTwoFactorAuth = async ({ axios, user, token }) => {
  const patchObject = {
    op: 'execute',
    path: 'confirmTwoFactorAuth',
    value: {
      new: token,
    },
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const projectCancellationRequest = async ({ axios, user, projectIds }) => {
  const patchObject = {
    op: 'execute',
    path: 'projectCancellationRequest',
    value: {
      projectIds,
    },
  }

  const { data } = await axios.patch(`users/${user._id}`, patchObject)
  return data
}

export const isEmailDeliverableCheck = async ({ axios, email }) => {
  const { data } = await axios.get('users/is-email-deliverable', {
    params: {
      email,
    },
  })

  return data
}

export const getStripePlanSubscription = async ({ axios, userId }) => {
  const { data } = await axios.get(`users/${userId}/plan-subscriptions`)

  return data
}

export const getStripePaymentMethod = async ({ axios, userId }) => {
  const { data } = await axios.get(`users/${userId}/payment-methods`)

  return data
}

export const setStripePaymentMethod = async ({ axios, userId, paymentMethodId }) => {
  const { data } = await axios.post(`users/${userId}/payment-methods`, {
    paymentMethodId,
  })

  return data
}

export const createStripeSepaSetupIntent = async ({ axios, userId }) => {
  const { data } = await axios.post(`users/${userId}/payment-methods/create-sepa-setup-intent`)

  return data.clientSecret
}

export const cancelStripePlanSubscription = async ({ axios, userId }) => {
  const { data } = await axios.post(`users/${userId}/plan-subscription/cancel`)

  return data
}

// store patchAbortController outside
let patchAbortController = null

export const updateFavoritesProjectIds = async ({ axios, user, projectIds }) => {
  // cancel running patch request
  if (patchAbortController) {
    patchAbortController.abort()
  }

  const patchObject = {
    op: 'update',
    path: 'favoritesProjectIds',
    value: {
      new: projectIds,
    },
  }

  patchAbortController = new AbortController()

  return axios
    .patch(`users/${user._id}`, patchObject, {
      signal: patchAbortController.signal,
    })
}
