import projectTemplateDe from '@client-shared/project-templates/default.de'
import projectTemplateEn from '@client-shared/project-templates/default.en'
import projectTemplateEnGb from '@client-shared/project-templates/default.en-gb'
import projectTemplateEnUs from '@client-shared/project-templates/default.en-us'
import emailValidationRegex from '@client-shared/utils/email-validation-regex'
import isOnLocalhost from '@client-shared/utils/is-on-localhost.js'

import constants from './constants.js'

const API_VERSION = 'api/v2/'

export const baseConfig = {
  showDevLogins: false,
  showBuildDateInFooter: false,
  BACKUP_APP_SIZE: 9542041, // bytes
  TILE_SIZE: 512,
  debug: {
    isNetworkSlowdownActive: isOnLocalhost && false, // check for hostname to ensure this is never activated on app.planfred.com
  },
  trialPeriodDurationInDays: 30,
  api: {
    apiversion: API_VERSION,
    host: `/${API_VERSION}`, // default API host is same host with /api/v#/ prefix
    companionUrl: `/${API_VERSION}`, // default API host is same host with /api/v#/ prefix
  },
  customerFeatures: {
    isUserAllowedToCreateNewProject: () => true,
    enableAgbPrivacyConfirmation: false,
    disableUserRegistrationForm: true,
    htmlTagClass: '',
    hideFeatureAlerts: true,
    account: {
      disablePayment: true,
      disableDelete: true,
      disableUsedStorage: true,
    },
  },
  maxFileSizeForBaseConversion: 1024 * 1024 * 10, // 10 MB
  showGoToWebsiteLink: false,
  logoPath: new URLsrc/assets/img/logo-planfred.svg', import.meta.url).href,
  tasksBetaUsers: [ // If an array entry matches with the logged in users email (regex), a user can see the tasks tab
  ],
  headTitleTag: 'Planfred',
  emailRegex: emailValidationRegex,
  sanitizeAllowedTags: ['span', 'ul', 'li', 'strong', 'ol', 'italic', 'i', 'b', 'u', 'br', 'div', 'p', 'a'],
  sanitizeAllowedTagsSignature: ['span', 'ul', 'li', 'strong', 'ol', 'italic', 'i', 'b', 'u', 'br', 'div', 'p', 'img', 'figure', 'a'],
  defaultTimezone: 'Europe/Vienna',
  constants,
  showTabIndexOutline: true && window.location.hostname.endsWith('localhost'),
  newRevisionUploadPollTimeout: 5000, // Timeout for polling interval on new revisions
  startupDate: new Date(),
  daysUntilHardReload: 2, // Only WHOLE numbers allowed [1, 2, 3, ...],
  planfredDataMigrationUser: '111111111111111111111111',
  maxGroupImportCount: 25,
  maxZoomLevelInBackup: 4, // Max 1 + 4 + 16 + 64 = 85 files (Zoom level 5 would be + extra 256 files)
  MULTI_ACTION_BATCH_SIZE: 50,
  i18n: {
    availableLocales: [
      {
        code: 'de',
        iso: 'de-DE',
        labelLong: 'Deutsch',
        labelShort: 'DE',
      },
      {
        code: 'en',
        iso: 'en',
        labelLong: 'English',
        labelShort: 'EN',
      },
      {
        code: 'en-us',
        iso: 'en-US',
        labelLong: 'English',
        labelShort: 'EN',
      },
      {
        code: 'en-gb',
        iso: 'en-GB',
        labelLong: 'English',
        labelShort: 'EN',
      },
    ],
  },
  projectTemplates: {
    de: projectTemplateDe,
    en: projectTemplateEn,
    'en-us': projectTemplateEnUs,
    'en-gb': projectTemplateEnGb,
  },
  awsS3PublicReadFolder: 'public',
  availableCountryCodes: [ // Taken from https://gist.github.com/jylopez/7a3eb87e94981a579303a73cf72a5086
    'au',
    'at',
    'be',
    'bg',
    'br',
    'ca',
    'cy',
    'cz',
    'dk',
    'ee',
    'fi',
    'fr',
    'de',
    'gr',
    'hk',
    'hu',
    'hr',
    'in',
    'ie',
    'it',
    'jp',
    'lv',
    'lt',
    'lu',
    'my',
    'mt',
    'mx',
    'nl',
    'nz',
    'no',
    'pl',
    'pt',
    'ro',
    'sg',
    'sk',
    'si',
    'es',
    'se',
    'ch',
    'gb',
    'us',
  ],
}
