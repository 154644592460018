function getRandomInt (min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
}

// Add a random Integer to avoid the same UID generated multiple times
export default () => {
  return Date.now().toString() + '-' + getRandomInt(1, 1000)
}
