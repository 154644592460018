<template>
  <div>
    <div id="teleport-target-context-menu" />
    <div
      id="teleport-target-overlay"
      multiple
    />
    <div
      id="teleport-target-modal"
      multiple
    />
    <div
      id="teleport-target-clipboard"
    />

    <DefaultLayout
      v-if="pageLayout === 'default' && (!isProjectRoute || (isProjectRoute && hasProject))"
    >
      <router-view />
    </DefaultLayout>

    <PublicLayout
      v-if="pageLayout === 'public'"
    >
      <router-view />
    </PublicLayout>

    <router-view
      v-if="!pageLayout"
    />
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/default.vue'
import PublicLayout from '@/layouts/public.vue'

export default {
  components: {
    DefaultLayout,
    PublicLayout,
  },

  head () {
    // https://stackoverflow.com/questions/73635644/why-is-microsoft-edge-detecting-the-wrong-language-on-my-site
    return {
      htmlAttrs: {
        lang: this.lang,
        'xml:lang': this.lang,
      },

      meta: [
        { 'http-equiv': 'Content-Language', content: this.lang },
      ],
    }
  },

  computed: {
    lang () {
      return this.$i18n.locale.substring(0, 2)
    },

    pageLayout () {
      if (!this.$route.name) {
        return
      }
      return this.$route.meta.isPublic ? 'public' : 'default'
    },

    isProjectRoute () {
      return this.$route.name.startsWith('projects-projectId')
    },

    hasProject () {
      return this.$store.state.project.project
    },
  },

  watch: {
    '$store.state.layout.htmlClassNames': {
      handler (newValue) {
        const htmlTag = document.querySelector('html')

        htmlTag.classList = newValue.join(' ')
      },

      deep: true,
    },
  },

  created () {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize () {
      this.$store.commit('layout/RESET_WINDOW_WIDTH')
    },
  },
}
</script>
