import projectTemplateDe from '@client-shared/project-templates/app.agp-planserver.at.de'

import { baseConfig } from '../_config-base.js'

export default {
  ...baseConfig,
  api: {
    ...baseConfig.api,
    // host: `https://app.apg-planserver.at/${baseConfig.api.apiversion}`, // we use netlify proxy for /api requests
    host: `https://api.app.apg-planserver.at/${baseConfig.api.apiversion}`,
    companionUrl: `https://api.app.apg-planserver.at/${baseConfig.api.apiversion}`, // we use CORS for uppy companion calls
    websocketServerUrl: 'https://api.app.apg-planserver.at',
  },
  awsS3BucketHost: 'https://apgplanserverat.s3-accelerate.amazonaws.com',
  isApgPlanserver: true,
  headTitleTag: 'APG Planserver',
  logoPath: new URLsrc/assets/img/customer-theme/apgplanserverat/apgplanserverat_logo.png', import.meta.url).href,
  customerFeatures: {
    ...baseConfig.customerFeatures,
    htmlTagClass: 'theme theme--apgplanserverat',
    hideFeatureAlerts: true,
    accountActivationTermsKey: 'apg_planserver',
  },
  tasksBetaUsers: [ // If an array entry matches with the logged in users email (regex), a user can see the tasks tab
  ],
  projectTemplates: {
    ...baseConfig.projectTemplates,
    de: projectTemplateDe,
  },
}
