<template>
  <MessageBoxBase
    class="js-validation-error"
  >
    <slot />
  </MessageBoxBase>
</template>

<script>
import MessageBoxBase from '@/components/MessageBoxBase.vue'

export default {
  components: {
    MessageBoxBase,
  },
}
</script>
