<template>
  <PfModal
    ref="modal"
    @after:close-animation="$emit('after:close-animation')"
  >
    <template #title>
      Planfred Devtools: Upload Clipboard Files
    </template>

    <div
      class="com_modal__window-body com_modal__window-body--shaded"
    >
      <div>
        <input
          ref="fileNameInput"
          v-model="inputText"
          type="text"
          placeholder="Filename to add (Enter to confirm)"
          @keyup.enter="onInputEnter"
        >

        <MessageBoxWarning
          v-if="state === validStates.SHOW_MISSING_EXTENSION_WARNING"
          style="margin-top: 5px;"
        >
          File extension missing
        </MessageBoxWarning>

        <ul
          style="margin-top: 15px;"
        >
          <li
            v-for="customFile of customFiles"
            :key="customFile"
            style="list-style-type: none; margin-left: 25px; cursor: pointer; margin-bottom: 2px; display: flex; align-items: center;"
            @click="onClickCustomFileName(customFile.fileName)"
          >
            <PfCheckbox
              :checked="customFile.isSelected"
              style="margin-right: 8px;"
              tabindex="-1"
            />

            {{ customFile.fileName }}

            <PfIcon
              v-if="coppiedText !== customFile.fileName"
              name="copy"
              style="margin-left: 5px;"
              @click.stop="copyToClipboard(customFile.fileName)"
            />

            <PfIcon
              v-if="coppiedText === customFile.fileName"
              name="checkmark"
              style="margin-left: 5px;"
            />
          </li>
        </ul>
      </div>

      <div
        style="margin-top: 20px; border-top: 1px solid gray; padding-top: 20px;"
      >
        <div
          v-for="fileSet of fileSets"
          :key="fileSet.name"
          style="margin-bottom: 15px;"
        >
          <div
            style="font-weight: bold; font-size: 16px; margin-bottom: 10px; cursor: pointer;"
            @click="onClickFileSetName(fileSet.name)"
          >
            <PfCheckbox
              :checked="fileSet.selectedFileNames.length === fileSet.fileNames.length"
              style="margin-right: 5px;"
              tabindex="-1"
            />

            {{ fileSet.name }}
          </div>

          <ul>
            <li
              v-for="fileName of fileSet.fileNames"
              :key="fileName"
              style="list-style-type: none; margin-left: 25px; cursor: pointer; margin-bottom: 2px;"
              @click="onClickFileSetFileName({fileSetName: fileSet.name, fileName})"
            >
              <PfCheckbox
                :checked="fileSet.selectedFileNames.includes(fileName)"
                style="margin-right: 8px;"
                tabindex="-1"
              />

              {{ fileName }}

              <PfIcon
                v-if="coppiedText !== fileName"
                name="copy"
                style="margin-left: 5px;"
                @click.stop="copyToClipboard(fileName)"
              />

              <PfIcon
                v-if="coppiedText === fileName"
                name="checkmark"
                style="margin-left: 5px;"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <PfButtonBar
      class="com_modal__window-footer"
    >
      <PfButtonBarButton
        :is-enabled="fileNamesToAdd.length > 0"
        @click="onClickAdd"
      >
        Add {{ fileNamesToAdd.length }} files
      </PfButtonBarButton>

      <PfButtonBarLink
        data-testid="cancel-edit-item-name"
        @click="closeModal"
      >
        {{ $t('layout.cancel') }}
      </PfButtonBarLink>
    </PfButtonBar>
  </PfModal>
</template>

<script>
import ClipboardFile from '@client-shared/models/clipboard-file.model'

import MessageBoxWarning from '@/components/MessageBoxWarning.vue'
import PfButtonBar from '@/components/PfButtonBar.vue'
import PfButtonBarButton from '@/components/PfButtonBarButton.vue'
import PfButtonBarLink from '@/components/PfButtonBarLink.vue'
import PfCheckbox from '@/components/PfCheckbox.vue'
import PfModal from '@/components/PfModal.vue'

export default {
  components: {
    PfModal,
    PfCheckbox,
    PfButtonBar,
    PfButtonBarButton,
    PfButtonBarLink,
    MessageBoxWarning,
  },

  emits: [
    'after:close-animation',
    'after:save',
    'update:type',
  ],

  data () {
    const validStates = Object.freeze({
      INTIAL: 1,
      SHOW_MISSING_EXTENSION_WARNING: 2,
    })

    return {
      state: validStates.INITIAL,
      validStates,

      inputText: '',
      coppiedText: '',
      customFiles: [], // { fileName: String, isSelected: Boolean, extension: String }

      // fileSets: [
      //   {
      //     name: 'Exact Matches',
      //     selectedFileNames: [],
      //     fileNames: [
      //       'BP123-4-005_A1_HAUPT_EG_GR_VEP_G_20170303 EG Plan Vorentwurf.pdf',
      //       'BP123-4-005_A1_HAUPT_EG_GR_VEP_G_20170303 EG Plan Vorentwurf.dwg',

      //       'BP123-4-005_A2_HAUPT_EG_GR_AP_B.pdf',
      //       'BP123-4-005_A2_HAUPT_EG_GR_AP_B.dwg',

      //       'BP123-4-005_A5_NEBEN_UG_GR_AP_20231003 UG Plan.pdf',
      //       'BP123-4-005_A5_NEBEN_UG_GR_AP_20231003 UG Plan.dwg',
      //     ],
      //   },

      //   {
      //     name: 'Suggestions',
      //     selectedFileNames: [],
      //     fileNames: [
      //       'BP123-4-005_A1_HAUPT_OG02_GR_DP_C_20231012 OG02 Plan Vorentwurf.pdf',
      //       'BP123-4-005_A1_HAUPT_OG02_GR_DP_C_20231012 OG02 Plan Vorentwurf.dwg',

      //       'HAUPT Elektroplan Index C.pdf',
      //       'HAUPT Elektroplan Index C.dwg',
      //     ],
      //   },

      //   {
      //     name: 'Not assignable',
      //     selectedFileNames: [],
      //     fileNames: [
      //       'I have an invalid extension.docx',
      //     ],
      //   },
      // ],

      fileSets: [
        {
          name: 'Exact Matches',
          selectedFileNames: [],
          fileNames: [
            '12.34_EG_GR Index 3.pdf',
            '12.34_EG_GR Index 3.dwg',

            '1812 EG 2023-01-01.pdf',
            '1812 EG 2023-01-01.dwg',

            '2023 a110.105 00.01 Index AA.pdf',
            '2023 a110.105 00.01 Index AA.dwg',
          ],
        },

        {
          name: 'Suggestions',
          selectedFileNames: [],
          fileNames: [
            '2099 OG_GR 2.pdf',

            'Freigabeplan 987_EG mit Kommentar 2.pdf',
          ],
        },

        {
          name: 'Not assignable',
          selectedFileNames: [],
          fileNames: [
            'I have an invalid extension.docx',
          ],
        },
      ],
    }
  },

  computed: {
    fileNamesToAdd () {
      const selectedCustomFiles = this.customFiles.filter(customFile => customFile.isSelected)

      return [
        ...new Set([
          ...selectedCustomFiles.map(customFile => customFile.fileName),
          ...this.fileSets.flatMap(fileSet => fileSet.selectedFileNames),
        ]),
      ]
    },
  },

  mounted () {
    this.$nextTick(() => {
      this.$refs.fileNameInput.focus()
    })
  },

  methods: {
    copyToClipboard (text) {
      navigator.clipboard.writeText(text)

      this.coppiedText = text

      window.setTimeout(() => {
        this.coppiedText = ''
      }, 3000)
    },

    closeModal () {
      this.$refs.modal && this.$refs.modal.close()
    },

    onClickAdd () {
      if (this.fileNamesToAdd.length === 0) {
        return
      }

      for (const fileName of this.fileNamesToAdd) {
        const normalizedFileName = encodeURIComponent(fileName.normalize())

        const isFileAlreadyAdded = this.$store.state.clipboard.files.find(clipboardFile => clipboardFile.name === normalizedFileName)

        if (isFileAlreadyAdded) {
          continue
        }

        const fileBlob = new Blob(['dummy content'], { type: 'text/plain' })
        fileBlob.lastModifiedDate = ''
        fileBlob.name = fileName

        const addedFileId = this.$clipboardUppy.addFile({
          name: normalizedFileName,
          type: fileBlob.type,
          data: fileBlob,
          meta: {
            projectId: this.$route.params.projectId,
            filesize: fileBlob.size,
            qqfilename: normalizedFileName,
          },
        })

        const uppyFile = this.$clipboardUppy.store.state.files[addedFileId]

        this.$store.commit('clipboard/ADD_FILE', ClipboardFile.createFromUppyFile(uppyFile))
      }

      this.closeModal()
    },

    onInputEnter () {
      const hasExtension = this.inputText.includes('.')

      if (!hasExtension) {
        this.state = this.validStates.SHOW_MISSING_EXTENSION_WARNING

        window.setTimeout(() => {
          this.state = this.validStates.INITIAL
        }, 3000)

        return
      }

      this.state = this.validStates.INITIAL

      const doesFileAlreadyExist = this.fileNamesToAdd.includes(this.inputText)

      if (!doesFileAlreadyExist) {
        this.customFiles.push({
          fileName: this.inputText,
          isSelected: true,
        })
      }

      this.inputText = ''
    },

    onClickFileSetName (clickedFileSetName) {
      const fileSet = this.fileSets.find(fs => fs.name === clickedFileSetName)

      if (!fileSet) {
        throw new Error(`File Set ${clickedFileSetName} not found`)
      }

      const areAllFilesSelected = fileSet.selectedFileNames.length === fileSet.fileNames.length

      if (areAllFilesSelected) {
        fileSet.selectedFileNames = []
      } else {
        fileSet.selectedFileNames = [...fileSet.fileNames]
      }
    },

    onClickFileSetFileName ({ fileSetName: clickedFileSetName, fileName: clickedFileName }) {
      const fileSet = this.fileSets.find(fs => fs.name === clickedFileSetName)

      if (!fileSet) {
        throw new Error(`File Set "${clickedFileSetName}" not found`)
      }

      if (!fileSet.fileNames.includes(clickedFileName)) {
        throw new Error(`File Set "${clickedFileSetName}" does not contain file "${clickedFileName}"`)
      }

      const isFileSelected = fileSet.selectedFileNames.includes(clickedFileName)

      if (isFileSelected) {
        fileSet.selectedFileNames = fileSet.selectedFileNames.filter(fileName => fileName !== clickedFileName)
      } else {
        fileSet.selectedFileNames.push(clickedFileName)
      }
    },

    onClickCustomFileName (fileName) {
      const customFile = this.customFiles.find(customFile => customFile.fileName === fileName)

      if (!customFile) {
        throw new Error(`Custom file "${fileName}" not found`)
      }

      customFile.isSelected = !customFile.isSelected
    },
  },
}
</script>
