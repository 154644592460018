import isImageByMimeType from './is-image-by-mime-type'
import { safeDate } from './safe-date'
import constants from '../config/constants.js'

const sortMultiDimensional = function (sortings) {
  if (!Array.isArray(sortings)) {
    throw new TypeError(
      'Passed value to "sortBase" function must be of type Array',
    )
  }

  const sorting = sortings[0]
  sortings.shift()

  if (sorting.direction === 'desc') {
    return sortDesc(sorting.valA, sorting.valB, sortings)
  }

  return sortAsc(sorting.valA, sorting.valB, sortings)
}

const sortAsc = function (compareValueA, compareValueB, sortings) {
  const naturalSortResult = sortNatural(compareValueA, compareValueB)

  if (naturalSortResult !== 0) {
    return naturalSortResult
  }

  if (!sortings || !sortings.length) {
    return naturalSortResult
  }

  return sortMultiDimensional(sortings)
}

const sortDesc = function (compareValueA, compareValueB, sortings) {
  const naturalSortResult = sortNatural(compareValueB, compareValueA)

  if (naturalSortResult !== 0) {
    return naturalSortResult
  }

  if (!sortings || !sortings.length) {
    return naturalSortResult
  }

  return sortMultiDimensional(sortings)
}

const sortByDateAsc = function (a, b) {
  const compareValueA = safeDate(a)
  const compareValueB = safeDate(b)

  return sortAsc(compareValueA, compareValueB)
}

const sortByDateDesc = function (a, b) {
  const compareValueA = safeDate(a)
  const compareValueB = safeDate(b)

  return sortDesc(compareValueA, compareValueB)
}

const sortAlphabeticallyCaseInsensitive = function (a, b) {
  const compareValueA = a && a.toLowerCase()
  const compareValueB = b && b.toLowerCase()

  return sortAsc(compareValueA, compareValueB)
}

const sortItemsByNameAsc = function (a, b) {
  const compareValueA = a.name.toLowerCase()
  const compareValueB = b.name.toLowerCase()

  return sortAsc(compareValueA, compareValueB)
}

const sortItemsByNameDesc = function (a, b) {
  const compareValueA = a.name.toLowerCase()
  const compareValueB = b.name.toLowerCase()

  return sortDesc(compareValueA, compareValueB)
}

const secondPropertyGetterForItemType = (item) => {
  if ('name' in item) {
    return function () {
      return item.name.toLowerCase()
    }
  }

  if ('roles' in item) {
    return function () {
      return item.roles[0] ? item.roles[0].toLowerCase() : ''
    }
  }

  return ''
}

const sortItemsBySelected = function (a, b) {
  if (!this || !this.selectedItemIds) {
    console.error(
      'sortItemsBySelected this context needs to be binded to the selectedItemIds',
    )
    return
  }

  const isSelectedA = this.selectedItemIds.find((id) => id === a._id)
  const isSelectedB = this.selectedItemIds.find((id) => id === b._id)

  return sortMultiDimensional([
    {
      valA: isSelectedA ? '0' : '1',
      valB: isSelectedB ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: secondPropertyGetterForItemType(a)(),
      valB: secondPropertyGetterForItemType(b)(),
      direction: 'asc',
    },
  ])
}

const sortItemsByIndex = function (a, b) {
  const indexA = a.computed.latestRevision.index.toLowerCase()
  const indexB = b.computed.latestRevision.index.toLowerCase()

  return sortMultiDimensional([
    {
      valA: indexA ? a.computed.latestRevision.index.toLowerCase() : '',
      valB: indexB ? b.computed.latestRevision.index.toLowerCase() : '',
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByContent = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.content ? a.content.toLowerCase() : '',
      valB: b.content ? b.content.toLowerCase() : '',
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByPhasetag = function (a, b) {
  if (!this || !this.project.phasetags) {
    console.error(
      'sortItemsByPhasetags this context needs to be binded to the project',
    )
    return
  }

  let projectPhasetags = this.project.phasetags

  if (a.computed.isDocument) {
    projectPhasetags = this.project.phasetagsDocuments
  }

  return sortMultiDimensional([
    {
      valA: a.phasetag ? projectPhasetags.indexOf(a.phasetag).toString() : '',
      valB: b.phasetag ? projectPhasetags.indexOf(b.phasetag).toString() : '',
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByTags = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.tags
        ? [...a.tags]
            .map((t) => t.toLowerCase())
            .sort()
            .join('')
        : '',
      valB: b.tags
        ? [...b.tags]
            .map((t) => t.toLowerCase())
            .sort()
            .join('')
        : '',
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByFilesPrint = function (a, b) {
  const revisionA = a.computed.latestRevision
  const revisionB = b.computed.latestRevision

  return sortMultiDimensional([
    {
      valA:
        revisionA && revisionA.pdf_file && revisionA.pdf_file.filename
          ? revisionA.pdf_file.filename.toLowerCase()
          : '',
      valB:
        revisionB && revisionB.pdf_file && revisionB.pdf_file.filename
          ? revisionB.pdf_file.filename.toLowerCase()
          : '',
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByFilesCad = function (a, b) {
  const revisionA = a.computed.latestRevision
  const revisionB = b.computed.latestRevision

  return sortMultiDimensional([
    {
      valA:
        revisionA && revisionA.dwg_file && revisionA.dwg_file.filename
          ? revisionA.dwg_file.filename.toLowerCase()
          : '',
      valB:
        revisionB && revisionB.dwg_file && revisionB.dwg_file.filename
          ? revisionB.dwg_file.filename.toLowerCase()
          : '',
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByFilesDocument = function (a, b) {
  const revisionA = a.computed.latestRevision
  const revisionB = b.computed.latestRevision

  return sortMultiDimensional([
    {
      valA:
        revisionA && revisionA.files && revisionA.files[0].filename
          ? revisionA.files[0].filename.toLowerCase()
          : '',
      valB:
        revisionB && revisionB.files && revisionB.files[0].filename
          ? revisionB.files[0].filename.toLowerCase()
          : '',
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByAuthor = function (a, b) {
  if (!this || !this.project.participants) {
    console.error(
      'sortItemsByAuthor this context needs to be binded to the project',
    )
    return
  }

  const aAuthorParticipant = this.project.findParticipantByPlanDocAuthor(
    a.author,
  )
  const bAuthorParticipant = this.project.findParticipantByPlanDocAuthor(
    b.author,
  )

  return sortMultiDimensional([
    {
      valA: aAuthorParticipant.company
        ? aAuthorParticipant.company.toLowerCase()
        : '',
      valB: bAuthorParticipant.company
        ? bAuthorParticipant.company.toLowerCase()
        : '',
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByApprovers = function (a, b) {
  const revisionA = a.computed.latestRevision
  const revisionB = b.computed.latestRevision

  return sortMultiDimensional([
    {
      valA:
        revisionA.approval && revisionA.approval.by
          ? revisionA.approval.by.length
          : 0,
      valB:
        revisionB.approval && revisionB.approval.by
          ? revisionB.approval.by.length
          : 0,
      direction: 'desc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByModifiedDate = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.modified,
      valB: b.modified,
      direction: 'desc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByLastApprovalDate = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.computed.latestApproverActionDate || '2000-00-00T12:00:00.000Z',
      valB: b.computed.latestApproverActionDate || '2000-00-00T12:00:00.000Z',
      direction: 'desc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByTopCategory = function (a, b) {
  if (!this || !this.project.topCategoriesPlans) {
    console.error(
      'sortItemsByTopCategory this context needs to be binded to the project',
    )
    return
  }

  let topCategories = this.project.topCategoriesPlans

  if (a.computed.isDocument) {
    topCategories = this.project.topCategoriesDocuments
  }

  return sortMultiDimensional([
    {
      valA: a.topCategory
        ? topCategories.indexOf(a.topCategory).toString()
        : '',
      valB: b.topCategory
        ? topCategories.indexOf(b.topCategory).toString()
        : '',
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortItemsByApprovalDateAsc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.computed.latestRevision.approvalStatus !== 'notrequested' ? '0' : '1',
      valB: b.computed.latestRevision.approvalStatus !== 'notrequested' ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: a.computed.latestRevision.approval.approvalDate ? '0' : '1',
      valB: b.computed.latestRevision.approval.approvalDate ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: a.computed.latestRevision.approval.approvalDate
        ? new Date(a.computed.latestRevision.approval.approvalDate)
        : '',
      valB: b.computed.latestRevision.approval.approvalDate
        ? new Date(b.computed.latestRevision.approval.approvalDate)
        : '',
      direction: 'desc',
    },
    {
      valA: a.name ? a.name.toLowerCase() : '',
      valB: b.name ? b.name.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortItemsByApprovalDateDesc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.computed.latestRevision.approvalStatus === 'pending' ? '0' : '1',
      valB: b.computed.latestRevision.approvalStatus === 'pending' ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: a.computed.latestRevision.approval.approvalDate ? '0' : '1',
      valB: b.computed.latestRevision.approval.approvalDate ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: a.computed.latestRevision.approval.approvalDate
        ? new Date(a.computed.latestRevision.approval.approvalDate)
        : '',
      valB: b.computed.latestRevision.approval.approvalDate
        ? new Date(b.computed.latestRevision.approval.approvalDate)
        : '',
      direction: 'desc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByTitleAsc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByTitleDesc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'desc',
    },
  ])
}

const sortTasksByTypeAsc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.type || '',
      valB: b.type || '',
      direction: 'asc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByTypeDesc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.type || '',
      valB: b.type || '',
      direction: 'desc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByDueDateAsc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.dueDate ? '0' : '1',
      valB: b.dueDate ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: a.dueDate ? new Date(a.dueDate) : '',
      valB: b.dueDate ? new Date(b.dueDate) : '',
      direction: 'asc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByDueDateDesc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.dueDate ? '0' : '1',
      valB: b.dueDate ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: a.dueDate ? new Date(a.dueDate) : '',
      valB: b.dueDate ? new Date(b.dueDate) : '',
      direction: 'desc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByCreatedAsc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.created ? new Date(a.created) : '',
      valB: b.created ? new Date(b.created) : '',
      direction: 'asc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByCreatedDesc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.created ? new Date(a.created) : '',
      valB: b.created ? new Date(b.created) : '',
      direction: 'desc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByPublishedAsc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: new Date(a.published || a.created),
      valB: new Date(b.published || b.created),
      direction: 'asc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByPublishedDesc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: new Date(a.published || a.created),
      valB: new Date(b.published || b.created),
      direction: 'desc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByModifiedAsc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.modified ? new Date(a.modified) : new Date(a.created),
      valB: b.modified ? new Date(b.modified) : new Date(b.created),
      direction: 'asc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByModifiedDesc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.modified ? new Date(a.modified) : new Date(a.created),
      valB: b.modified ? new Date(b.modified) : new Date(b.created),
      direction: 'desc',
    },
    {
      valA: a.title ? a.title.toLowerCase() : '',
      valB: b.title ? b.title.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByStateAsc = function (a, b) {
  const sortOrder = {
    [constants.VALID_TASK_STATES.DRAFT]: 1,
    [constants.VALID_TASK_STATES.QUESTION]: 2,
    [constants.VALID_TASK_STATES.EXECUTION]: 3,
    [constants.VALID_TASK_STATES.OPEN]: 4,
    [constants.VALID_TASK_STATES.DONE]: 5,
  } // we define the order, how the permission should be sorted. Otherwise admin would be on the 1st position

  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: sortOrder[a.state].toString(),
      valB: sortOrder[b.state].toString(),
      direction: 'asc',
    },
    {
      valA: a.modified ? new Date(a.modified) : new Date(a.created),
      valB: b.modified ? new Date(b.modified) : new Date(b.created),
      direction: 'desc',
    },
  ])
}

const sortTasksByStateDesc = function (a, b) {
  const sortOrder = {
    [constants.VALID_TASK_STATES.DRAFT]: 1,
    [constants.VALID_TASK_STATES.QUESTION]: 2,
    [constants.VALID_TASK_STATES.EXECUTION]: 3,
    [constants.VALID_TASK_STATES.OPEN]: 4,
    [constants.VALID_TASK_STATES.DONE]: 5,
  } // we define the order, how the permission should be sorted. Otherwise admin would be on the 1st position

  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: sortOrder[a.state].toString(),
      valB: sortOrder[b.state].toString(),
      direction: 'desc',
    },
    {
      valA: a.modified ? new Date(a.modified) : new Date(a.created),
      valB: b.modified ? new Date(b.modified) : new Date(b.created),
      direction: 'desc',
    },
  ])
}

const sortTasksByAssigneeAsc = function (a, b) {
  if (!this || !this.project.participants) {
    console.error(
      'sortTasksByAssigneeAsc this context needs to be binded to the project',
    )
    return
  }

  const aAssigneeParticipant = this.project.findParticipantByUserId(
    a.assigneeUserId,
  )
  const bAssigneeParticipant = this.project.findParticipantByUserId(
    b.assigneeUserId,
  )

  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA:
        aAssigneeParticipant && aAssigneeParticipant.roles[0]
          ? aAssigneeParticipant.roles[0].toLowerCase()
          : '',
      valB:
        bAssigneeParticipant && bAssigneeParticipant.roles[0]
          ? bAssigneeParticipant.roles[0].toLowerCase()
          : '',
      direction: 'asc',
    },
    {
      valA: aAssigneeParticipant && aAssigneeParticipant.lastname.toLowerCase(),
      valB: bAssigneeParticipant && bAssigneeParticipant.lastname.toLowerCase(),
      direction: 'asc',
    },
    {
      valA:
        aAssigneeParticipant && aAssigneeParticipant.firstname.toLowerCase(),
      valB:
        bAssigneeParticipant && bAssigneeParticipant.firstname.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortTasksByAssigneeDesc = function (a, b) {
  if (!this || !this.project.participants) {
    console.error(
      'sortTasksByAssigneeDesc this context needs to be binded to the project',
    )
    return
  }

  const aAssigneeParticipant = this.project.findParticipantByUserId(
    a.assigneeUserId,
  )
  const bAssigneeParticipant = this.project.findParticipantByUserId(
    b.assigneeUserId,
  )

  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA:
        aAssigneeParticipant && aAssigneeParticipant.roles[0]
          ? aAssigneeParticipant.roles[0].toLowerCase()
          : '',
      valB:
        bAssigneeParticipant && bAssigneeParticipant.roles[0]
          ? bAssigneeParticipant.roles[0].toLowerCase()
          : '',
      direction: 'desc',
    },
    {
      valA:
        aAssigneeParticipant && aAssigneeParticipant.lastname
          ? aAssigneeParticipant.lastname.toLowerCase()
          : '',
      valB:
        bAssigneeParticipant && bAssigneeParticipant.lastname
          ? bAssigneeParticipant.lastname.toLowerCase()
          : '',
      direction: 'asc',
    },
    {
      valA:
        aAssigneeParticipant && aAssigneeParticipant.firstname
          ? aAssigneeParticipant.firstname.toLowerCase()
          : '',
      valB:
        bAssigneeParticipant && bAssigneeParticipant.firstname
          ? bAssigneeParticipant.firstname.toLowerCase()
          : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByCreatorAsc = function (a, b) {
  if (!this || !this.project.participants) {
    console.error(
      'sortTasksByCreatorAsc this context needs to be binded to the project',
    )
    return
  }

  const aCreatorParticipant = this.project.findParticipantByUserId(
    a.creatorUserId,
  )
  const bCreatorParticipant = this.project.findParticipantByUserId(
    b.creatorUserId,
  )

  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: aCreatorParticipant && aCreatorParticipant.lastname.toLowerCase(),
      valB: bCreatorParticipant && bCreatorParticipant.lastname.toLowerCase(),
      direction: 'asc',
    },
    {
      valA: aCreatorParticipant && aCreatorParticipant.firstname.toLowerCase(),
      valB: bCreatorParticipant && bCreatorParticipant.firstname.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortTasksByCreatorDesc = function (a, b) {
  if (!this || !this.project.participants) {
    console.error(
      'sortTasksByCreatorDesc this context needs to be binded to the project',
    )
    return
  }

  const aCreatorParticipant = this.project.findParticipantByUserId(
    a.creatorUserId,
  )
  const bCreatorParticipant = this.project.findParticipantByUserId(
    b.creatorUserId,
  )

  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA:
        aCreatorParticipant && aCreatorParticipant.lastname
          ? aCreatorParticipant.lastname.toLowerCase()
          : '',
      valB:
        bCreatorParticipant && bCreatorParticipant.lastname
          ? bCreatorParticipant.lastname.toLowerCase()
          : '',
      direction: 'desc',
    },
    {
      valA:
        aCreatorParticipant && aCreatorParticipant.firstname
          ? aCreatorParticipant.firstname.toLowerCase()
          : '',
      valB:
        bCreatorParticipant && bCreatorParticipant.firstname
          ? bCreatorParticipant.firstname.toLowerCase()
          : '',
      direction: 'asc',
    },
  ])
}

const sortTasksByLocationAsc = function (a, b) {
  if (!this || !this.project.participants) {
    console.error(
      'sortTasksByLocationAsc this context needs to be binded to the project',
    )
    return
  }

  const locationPropertiesSorted = [
    'location1',
    'location2',
    'location3',
    'location4',
  ]
    .sort((a, b) =>
      this.project.locations[a].order > this.project.locations[b].order ? 1 : -1,
    )
    .filter(
      (locationProperty) => this.project.locations[locationProperty].isEnabled,
    )

  const sortArray = [
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
  ]

  // Sort by location properties (ordered by location property order, which is defined in the project model)
  locationPropertiesSorted.forEach((locationProperty) => {
    sortArray.push({
      valA: a[locationProperty] ? '0' : '1',
      valB: b[locationProperty] ? '0' : '1',
      direction: 'asc',
    })
    sortArray.push({
      valA: a[locationProperty]
        ? this.project.locations[locationProperty].values.indexOf(
          a[locationProperty],
        )
        : '',
      valB: b[locationProperty]
        ? this.project.locations[locationProperty].values.indexOf(
          b[locationProperty],
        )
        : '',
      direction: 'asc',
    })
  })

  return sortMultiDimensional(sortArray)
}

const sortTasksByLocationDesc = function (a, b) {
  if (!this || !this.project.participants) {
    console.error(
      'sortTasksByLocationDesc this context needs to be binded to the project',
    )
    return
  }

  const locationPropertiesSorted = [
    'location1',
    'location2',
    'location3',
    'location4',
  ]
    .sort((a, b) =>
      this.project.locations[a].order > this.project.locations[b].order ? 1 : -1,
    )
    .filter(
      (locationProperty) => this.project.locations[locationProperty].isEnabled,
    )

  const sortArray = [
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
  ]

  // Sort by location properties (ordered by location property order, which is defined in the project model)
  locationPropertiesSorted.forEach((locationProperty) => {
    sortArray.push({
      valA: a[locationProperty] ? '0' : '1',
      valB: b[locationProperty] ? '0' : '1',
      direction: 'asc',
    })
    sortArray.push({
      valA: a[locationProperty]
        ? this.project.locations[locationProperty].values.indexOf(
          a[locationProperty],
        )
        : '',
      valB: b[locationProperty]
        ? this.project.locations[locationProperty].values.indexOf(
          b[locationProperty],
        )
        : '',
      direction: 'desc',
    })
  })

  return sortMultiDimensional(sortArray)
}

const sortTasksByConsecutiveNumberAsc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.consecutiveNumber ? '0' : '1',
      valB: b.consecutiveNumber ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: a.consecutiveNumber,
      valB: b.consecutiveNumber,
      direction: 'asc',
    },
    {
      valA: new Date(a.created),
      valB: new Date(b.created),
      direction: 'asc',
    },
  ])
}

const sortTasksByConsecutiveNumberDesc = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.consecutiveNumber ? '0' : '1',
      valB: b.consecutiveNumber ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: a.consecutiveNumber,
      valB: b.consecutiveNumber,
      direction: 'desc',
    },
    {
      valA: new Date(a.created),
      valB: new Date(b.created),
      direction: 'desc',
    },
  ])
}

const sortTasksByFilesAsc = function (a, b) {
  const aHasImage = a.files.find((file) => isImageByMimeType(file.mimeType))
  const bHasImage = b.files.find((file) => isImageByMimeType(file.mimeType))

  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: aHasImage ? '0' : '1',
      valB: bHasImage ? '0' : '1',
      direction: 'asc',
    },
  ])
}

const sortTasksByFilesDesc = function (a, b) {
  const aHasImage = a.files.find((file) => isImageByMimeType(file.mimeType))
  const bHasImage = b.files.find((file) => isImageByMimeType(file.mimeType))

  return sortMultiDimensional([
    {
      valA: a.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      valB: b.state === constants.VALID_TASK_STATES.DONE ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: aHasImage ? '0' : '1',
      valB: bHasImage ? '0' : '1',
      direction: 'desc',
    },
  ])
}

const sortParticipantsByRole = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.roles[0] ? a.roles[0].toLowerCase() : '',
      valB: b.roles[0] ? b.roles[0].toLowerCase() : '',
      direction: 'asc',
    },
    {
      valA: a.lastname.toLowerCase(),
      valB: b.lastname.toLowerCase(),
      direction: 'asc',
    },
    {
      valA: a.company ? a.company.toLowerCase() : '',
      valB: b.company ? b.company.toLowerCase() : '',
    },
  ])
}

const sortParticipantsBySilentPermissionRole = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.computed.isVirtual ? '1' : '0',
      valB: b.computed.isVirtual ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.permission === 'silent' ? '1' : '0',
      valB: b.permission === 'silent' ? '1' : '0',
      direction: 'asc',
    },
    {
      valA: a.roles[0] + a.lastname,
      valB: b.roles[0] + b.lastname,
      direction: 'asc',
    },
  ])
}

const sortParticipantsByEmail = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.user.email ? a.user.email.toLowerCase() : '',
      valB: b.user.email ? b.user.email.toLowerCase() : '',
      direction: 'asc',
    },
    {
      valA: a.lastname ? a.lastname.toLowerCase() : '',
      valB: b.lastname ? b.lastname.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortParticipantsByLastName = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.lastname ? a.lastname.toLowerCase() : '',
      valB: b.lastname ? b.lastname.toLowerCase() : '',
      direction: 'asc',
    },
    {
      valA: a.firstname ? a.firstname.toLowerCase() : '',
      valB: b.firstname ? b.firstname.toLowerCase() : '',
      direction: 'asc',
    },
    {
      valA: a.user.email ? a.user.email.toLowerCase() : '',
      valB: b.user.email ? b.user.email.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortParticipantsByCompany = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.company ? a.company.toLowerCase() : '',
      valB: b.company ? b.company.toLowerCase() : '',
      direction: 'asc',
    },
    {
      valA: a.lastname ? a.lastname.toLowerCase() : '',
      valB: b.lastname ? b.lastname.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortParticipantsByPhone = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.phone ? a.phone.toLowerCase() : '',
      valB: b.phone ? b.phone.toLowerCase() : '',
      direction: 'asc',
    },
    {
      valA: a.lastname ? a.lastname.toLowerCase() : '',
      valB: b.lastname ? b.lastname.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortParticipantsByPermission = function (a, b) {
  const sortOrder = {
    owner: 1,
    admin: 2,
    participant: 3,
    silent: 4,
  } // we define the order, how the permission should be sorted. Otherwise admin would be on the 1st position

  return sortMultiDimensional([
    {
      valA: sortOrder[a.permission].toString(),
      valB: sortOrder[b.permission].toString(),
      direction: 'asc',
    },
    {
      valA: a.lastname ? a.lastname.toLowerCase() : '',
      valB: b.lastname ? b.lastname.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortParticipantsBySelected = function (a, b) {
  if (!this || !this.selectedParticipantIds) {
    console.error(
      'sortParticipantsBySelected this context needs to be binded to the selectedParticipantIds',
    )
    return
  }

  const isSelectedA = this.selectedParticipantIds.find((id) => id === a._id)
  const isSelectedB = this.selectedParticipantIds.find((id) => id === b._id)

  return sortMultiDimensional([
    {
      valA: isSelectedA ? '0' : '1',
      valB: isSelectedB ? '0' : '1',
      direction: 'asc',
    },
    {
      valA: a.lastname ? a.lastname.toLowerCase() : '',
      valB: b.lastname ? b.lastname.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortParticipantsByCanApproveRevisions = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.canapproverevisions,
      valB: b.canapproverevisions,
      direction: 'asc',
    },
    {
      valA: a.lastname ? a.lastname.toLowerCase() : '',
      valB: b.lastname ? b.lastname.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortParticipantsByUploadAlert = function (a, b) {
  // If sorting by uploadAlert setting is enabled, we want to have it sorted like this: hourly, daily, off
  const sortOrder = {
    hourly: 1,
    daily: 2,
    off: 3,
  }

  return sortMultiDimensional([
    {
      valA: sortOrder[a.uploadAlert.status].toString(),
      valB: sortOrder[b.uploadAlert.status].toString(),
      direction: 'asc',
    },
    {
      valA: a.lastname ? a.lastname.toLowerCase() : '',
      valB: b.lastname ? b.lastname.toLowerCase() : '',
      direction: 'asc',
    },
    {
      valA: a.firstname ? a.firstname.toLowerCase() : '',
      valB: b.firstname ? b.firstname.toLowerCase() : '',
      direction: 'asc',
    },
  ])
}

const sortNatural = function (a, b) {
  if (!(a instanceof Date)) {
    a = a ? a.toString() : ''
  }
  if (!(b instanceof Date)) {
    b = b ? b.toString() : ''
  }

  // Natural sort which is used by windows for example.
  // This implementation is based on: http://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
  if (!a.localeCompare) {
    return a > b ? 1 : -1 // Fallback for boolean values
  }

  let language
  // IE11 does not know navigator.languages, so we have to do this check
  if (navigator.languages) {
    language = navigator.languages[0]
  } else {
    language = navigator.language
  }

  try {
    return a.localeCompare(b, language, { numeric: true })
  } catch (err) {
    return a > b ? 1 : -1
  }
}

const sortClipboardFilesByName = function (a, b, order) {
  const nameWithoutExtensionA = a.name.toLowerCase().split('.').slice(0, -1).join('.')
  const nameWithoutExtensionB = b.name.toLowerCase().split('.').slice(0, -1).join('.')

  return sortMultiDimensional([
    {
      valA: nameWithoutExtensionA,
      valB: nameWithoutExtensionB,
      direction: order,
    },
    {
      valA: a.extension.toLowerCase(),
      valB: b.extension.toLowerCase(),
      direction: 'desc',
    },
  ])
}

const sortClipboardFilesByNameAsc = function (a, b) {
  return sortClipboardFilesByName(a, b, 'asc')
}

const sortClipboardFilesByNameDesc = function (a, b) {
  return sortClipboardFilesByName(a, b, 'desc')
}

const sortClipboardFilesByType = function (a, b) {
  return sortMultiDimensional([
    {
      valA: a.extension.toLowerCase(),
      valB: b.extension.toLowerCase(),
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortClipboardFilesByStatus = function (a, b) {
  const sortOrder = {
    isError: 1,
    isUploading: 2,
    isUnassigned: 3,
    other: 4,
  }

  let statusA = sortOrder.other
  let statusB = sortOrder.other

  if (a.uploadError) {
    statusA = sortOrder.isError
  } else if (a.isUploadRunning) {
    statusA = sortOrder.isUploading
  } else if (a.isUnassigned) {
    statusA = sortOrder.isUnassigned
  }

  if (b.uploadError) {
    statusB = sortOrder.isError
  } else if (b.isUploadRunning) {
    statusB = sortOrder.isUploading
  } else if (b.isUnassigned) {
    statusB = sortOrder.isUnassigned
  }

  return sortMultiDimensional([
    {
      valA: statusA.toString(),
      valB: statusB.toString(),
      direction: 'asc',
    },
    {
      valA: a.name.toLowerCase(),
      valB: b.name.toLowerCase(),
      direction: 'asc',
    },
  ])
}

const sortLocationLayersByOrder = function (a, b) {
  // Don't use sortMultiDimensional, as this would rank 0 after 1, 2, 3, ...
  return a.order - b.order || new Date(a.created) - new Date(b.created)
}

export const sortProjectsByTitleAsc = (a, b) => sortAsc(a.title, b.title)

export const sortProjectsByTitleDesc = (a, b) => sortDesc(a.title, b.title)

export const sortProjectsByCreatedAt = (a, b) => {
  return sortByDateDesc(a.created, b.created)
}

export const sortProjectsByLastRevisionUpdate = (a, b) => {
  return sortByDateDesc(a.lastrevisionupload, b.lastrevisionupload)
}

const sortClipboardFileAssignmentsByNameAsc = function (a, b) {
  const compareValueA = a.candidatePackages[0]?.plan?.name?.toLowerCase()
  const compareValueB = b.candidatePackages[0]?.plan?.name?.toLowerCase()

  return sortAsc(compareValueA, compareValueB)
}

export {
  sortAlphabeticallyCaseInsensitive,
  sortByDateAsc,
  sortByDateDesc,
  sortItemsByNameAsc,
  sortItemsByNameDesc,
  sortItemsBySelected,
  sortItemsByIndex,
  sortItemsByContent,
  sortItemsByPhasetag,
  sortItemsByTags,
  sortItemsByFilesPrint,
  sortItemsByFilesCad,
  sortItemsByFilesDocument,
  sortItemsByAuthor,
  sortItemsByApprovers,
  sortItemsByModifiedDate,
  sortItemsByLastApprovalDate,
  sortItemsByTopCategory,
  sortItemsByApprovalDateAsc,
  sortItemsByApprovalDateDesc,
  sortTasksByTitleAsc,
  sortTasksByTitleDesc,
  sortTasksByTypeAsc,
  sortTasksByTypeDesc,
  sortTasksByCreatedAsc,
  sortTasksByCreatedDesc,
  sortTasksByPublishedAsc,
  sortTasksByPublishedDesc,
  sortTasksByDueDateAsc,
  sortTasksByDueDateDesc,
  sortTasksByModifiedAsc,
  sortTasksByModifiedDesc,
  sortTasksByStateAsc,
  sortTasksByStateDesc,
  sortTasksByAssigneeAsc,
  sortTasksByAssigneeDesc,
  sortTasksByCreatorAsc,
  sortTasksByCreatorDesc,
  sortTasksByLocationAsc,
  sortTasksByLocationDesc,
  sortTasksByConsecutiveNumberAsc,
  sortTasksByConsecutiveNumberDesc,
  sortTasksByFilesAsc,
  sortTasksByFilesDesc,
  sortParticipantsByEmail,
  sortParticipantsByRole,
  sortParticipantsBySilentPermissionRole,
  sortParticipantsByLastName,
  sortParticipantsByCompany,
  sortParticipantsByPhone,
  sortParticipantsByPermission,
  sortParticipantsByCanApproveRevisions,
  sortParticipantsByUploadAlert,
  sortParticipantsBySelected,
  sortClipboardFilesByNameAsc,
  sortClipboardFilesByNameDesc,
  sortClipboardFilesByType,
  sortClipboardFilesByStatus,
  sortLocationLayersByOrder,
  sortMultiDimensional,
  sortNatural,
  sortClipboardFileAssignmentsByNameAsc,
}
