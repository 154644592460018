import * as PlansApi from '@client-shared/api/plans.api.js'
import filterFunctions from '@client-shared/utils/filter-functions.js'

import constants from '@/config/constants.js'

export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
      markedItem: null,
      showSelectedInFiltered: true,

      sortSettings: {
        sortProperty: constants.SORT_PROPERTIES.ITEM_NAME,
        sortOrderReversed: false,
      },

      printSettings: {
        groupBy: constants.ITEM_PRINT_SETTINGS.GROUP_BY_NONE,
        showItemUrl: false,
        showComments: false,
      },
    }
  },

  getters: {
    getById: (state) => {
      return planId => state.list.find(plan => plan._id === planId)
    },

    getByName: (state) => {
      return planName => { return state.list.filter(plan => plan.name === planName) }
    },

    trashedItems: (state) => {
      return state.list.filter(p => p.trashed)
    },

    listFilteredByModus: (state, getters, rootState, rootGetters) => {
      const modus = rootState.plansListModus.modus

      if (modus === constants.LIST_MODUS.APPROVAL) {
        const plansNotTrashed = state.list.filter(p => !p.trashed)
        const project = rootState.project.project
        const currentUserParticipant = project.findParticipantByUserId(rootState.currentUser.user._id)

        if (!currentUserParticipant) {
          return plansNotTrashed
        }

        return plansNotTrashed.filter(p => {
          const planApproval = p.computed.latestRevision.approval

          if (!planApproval || !planApproval.by) {
            return false
          }

          const approver = p.computed.latestRevision.approval.by.find(by => by.participantid === currentUserParticipant._id)

          if (approver && approver.approved === 'pending') {
            return true
          }

          return false
        })
      } else if (modus === constants.LIST_MODUS.TRASH) {
        return getters.trashedItems
      } else if (modus === constants.LIST_MODUS.LIST || modus === constants.LIST_MODUS.PRINT || modus === constants.LIST_MODUS.MULTI_ACTION) {
        return state.list.filter(plan => !plan.trashed)
      } else if (modus === constants.LIST_MODUS.UPDATE || modus === constants.LIST_MODUS.MULTI_ACTION_EDIT) {
        return state.list.filter(plan => {
          return !plan.trashed && rootGetters['acl/isAllowedToUpdatePlanDoc'](plan)
        })
      }

      return []
    },

    listFiltered: (state, getters, rootState) => {
      return getters.listFilteredByModus.filter(plan => filterFunctions.plans.matchesFilters({
        project: rootState.project.project,
        item: plan,
        ...rootState[rootState.plansListModus.filterStoreName],
      }))
    },
  },

  mutations: {
    SET_PLANS (state, plans) {
      state.list = plans
    },

    ADD_PLAN (state, plan) {
      const existingIndex = state.list.findIndex(p => p._id === plan._id)

      if (existingIndex < 0) {
        state.list.push(plan)
        return
      }

      state.list[existingIndex] = plan
    },

    REPLACE_PLAN (state, plan) {
      const planIndex = state.list.findIndex(p => p._id === plan._id)

      state.list[planIndex] = plan
    },

    RESET_PLANS (state) {
      state.list = []
    },

    FULL_RESET (state) {
      state.list = []
      state.markedItem = null

      state.sortSettings = {
        sortProperty: constants.SORT_PROPERTIES.ITEM_NAME,
        sortOrderReversed: false,
      }

      state.printSettings = {
        groupBy: constants.ITEM_PRINT_SETTINGS.GROUP_BY_NONE,
        showItemUrl: false,
        showComments: false,
      }
    },

    SET_MARKED_ITEM (state, item) {
      state.markedItem = item
    },

    RESET_SORTING (state, item) {
      state.sortSettings.sortProperty = constants.SORT_PROPERTIES.ITEM_NAME
      state.sortSettings.sortOrderReversed = false
    },

    SET_SORT_PROPERTY (state, sortProperty) {
      state.sortSettings.sortProperty = sortProperty
    },

    SET_SORT_ORDER_REVERSED (state, sortOrderReversed) {
      state.sortSettings.sortOrderReversed = sortOrderReversed
    },

    SET_GROUP_BY (state, groupBy) {
      const validModi = [
        constants.ITEM_PRINT_SETTINGS.GROUP_BY_TOP_CATEGORIES,
        constants.ITEM_PRINT_SETTINGS.GROUP_BY_ROLES,
        constants.ITEM_PRINT_SETTINGS.GROUP_BY_PHASETAGS,
        constants.ITEM_PRINT_SETTINGS.GROUP_BY_NONE,
      ]

      if (!validModi.includes(groupBy)) {
        console.error(`${groupBy} is not a valid grouping mode for plans list`)
        return
      }
      state.printSettings.groupBy = groupBy
    },

    SET_SHOW_ITEM_URL (state, newValue) {
      state.printSettings.showItemUrl = newValue
    },

    SET_SHOW_COMMENTS (state, newValue) {
      state.printSettings.showComments = newValue
    },

    SET_SHOW_SELECTED_IN_FILTERED (state, newValue) {
      state.showSelectedInFiltered = newValue
    },
  },

  actions: {
    async fetch ({ commit }, projectId) {
      const plans = await PlansApi.getPlans({
        axios: this.$axios,
        projectId,
      })

      commit('SET_PLANS', plans)
    },

    async fetchById ({ commit }, planId) {
      const plan = await PlansApi.getPlan({
        axios: this.$axios,
        planId,
      })

      commit('ADD_PLAN', plan)
    },

    async updatePlanTags ({ commit, dispatch }, { item, newValue }) {
      const updatedPlan = await PlansApi.updateTags({
        axios: this.$axios,
        plan: item,
        newValue,
      })
      await dispatch('project/fetch', item.projectid, { root: true })

      commit('REPLACE_PLAN', updatedPlan)
    },

    async updatePlanPhasetag ({ commit }, { item, newPhasetag }) {
      const updatedPlan = await PlansApi.updatePhasetag({
        axios: this.$axios,
        plan: item,
        newPhasetag,
      })

      commit('REPLACE_PLAN', updatedPlan)
    },

    async updatePlanTopCategory ({ commit }, { item, newTopCategory }) {
      const updatedPlan = await PlansApi.updateTopCategory({
        axios: this.$axios,
        plan: item,
        newTopCategory,
      })

      commit('REPLACE_PLAN', updatedPlan)
    },

    async addPlanRevision ({ commit }, { item, revision }) {
      const updatedPlan = await PlansApi.addRevision({
        axios: this.$axios,
        plan: item,
        revision,
      })

      commit('REPLACE_PLAN', updatedPlan)
    },

    async updatePlanTrashed ({ commit, dispatch }, { item, newTrashedValue }) {
      const updatedPlan = await PlansApi.updateTrashed({
        axios: this.$axios,
        plan: item,
        newTrashedValue,
      })

      commit('REPLACE_PLAN', updatedPlan)
      dispatch('project/fetch', this.state.project.project._id, { root: true })
    },

    async updateRevisionApprovalStatus ({ commit, dispatch }, { item, approver, approvalStatus, message, attachments }) {
      const updatedPlan = await PlansApi.updateRevisionApprovalStatus({
        axios: this.$axios,
        plan: item,
        approver,
        approvalStatus,
        message,
        attachments,
      })

      commit('REPLACE_PLAN', updatedPlan)
      dispatch('project/fetch', this.state.project.project._id, { root: true })
    },

    async addRevisionApprovalComment ({ commit, rootState }, { item, approver, message, attachments }) {
      const updatedPlan = await PlansApi.addRevisionApprovalComment({
        axios: this.$axios,
        plan: item,
        approver,
        message,
        attachments,
      })

      commit('REPLACE_PLAN', updatedPlan)
    },

    async updatePlanRevisionApproval ({ commit }, { item, revision, newParticipantIds, newApprovalMode, newApprovalRequestMessage, newApprovalDate }) {
      const updatedPlan = await PlansApi.updateRevisionApproval({
        axios: this.$axios,
        plan: item,
        revision,
        newParticipantIds,
        newApprovalMode,
        newApprovalRequestMessage,
        newApprovalDate,
      })

      commit('REPLACE_PLAN', updatedPlan)
    },

    async approveRevisionByMyself ({ commit }, { item, revision, comment }) {
      const updatedPlan = await PlansApi.approveRevisionByMyself({
        axios: this.$axios,
        plan: item,
        revision,
        comment,
      })

      commit('REPLACE_PLAN', updatedPlan)
    },

    async rejectRevisionByMyself ({ commit }, { item, revision, comment }) {
      const updatedPlan = await PlansApi.rejectRevisionByMyself({
        axios: this.$axios,
        plan: item,
        revision,
        comment,
      })

      commit('REPLACE_PLAN', updatedPlan)
    },

    async updatePlanAuthor ({ commit }, { item, newUserId, newRole }) {
      const updatedPlan = await PlansApi.updateAuthor({
        axios: this.$axios,
        plan: item,
        newUserId,
        newRole,
      })

      commit('REPLACE_PLAN', updatedPlan)
    },

    async updatePlanName ({ commit }, { item, newName, newContent }) {
      const updatedPlan = await PlansApi.updateName({
        axios: this.$axios,
        plan: item,
        newName,
        newContent,
      })

      commit('REPLACE_PLAN', updatedPlan)
    },
  },
}
