<template>
  <Teleport
    to="#teleport-target-modal"
    tag="div"
  >
    <div
      :data-testid="$attrs ? $attrs['data-testid'] : ''"
      :class="[
        $attrs.staticClass,
        $attrs.class,
        {
          'scroll-lock': hasScrollLock,
          'com_modal--sticky-footer': footerIsSticky,
          'com_modal--task-width': isSlim
        }
      ].filter(Boolean)"
      class="com_modal"
    >
      <div
        ref="modal"
        tabindex="0"
        class="com_modal__inner-wrap"
        @keyup.esc="onClickClose"
      >
        <div
          class="com_modal__flex-container"
        >
          <transition
            appear
            name="com_modal-fade"
            @after-leave="afterCloseAnimation"
          >
            <div
              v-if="isOpen"
              ref="modal-window"
              class="com_modal__window"
            >
              <ButtonClose
                v-if="showClose"
                mobile-size="sm"
                data-testid="modal-close-button"
                class="com_modal__x-close-button"
                @click="onClickClose"
              />

              <div
                data-testid="modal-content"
                class="com_modal__content"
              >
                <div
                  v-if="$slots.title"
                  class="com_modal__window-header"
                >
                  <div
                    class="com_modal__window-title"
                  >
                    <slot name="title" />
                  </div>
                </div>

                <slot />
              </div>
            </div>
          </transition>
        </div>
      </div>

      <transition
        appear
        name="darkener-fade"
      >
        <PfDarkener
          v-if="isOpen"
          @click="onClickOutside"
        />
      </transition>
    </div>
  </Teleport>
</template>

<script>
import isOnLocalhost from '@client-shared/utils/is-on-localhost'
import { vOnClickOutside } from '@vueuse/components'

import ButtonClose from '@/components/ButtonClose.vue'
import PfDarkener from '@/components/PfDarkener.vue'
import generateComponentUid from '@/utils/generate-component-uid.js'

export default {
  components: {
    PfDarkener,
    ButtonClose,
  },

  directives: {
    clickOutside: vOnClickOutside,
  },

  props: {
    showClose: {
      type: Boolean,
      default: true,
    },

    hasChanges: {
      type: Boolean,
      default: false,
    },

    footerIsSticky: {
      type: Boolean,
      default: false,
    },

    isSlim: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'after:close-animation',
  ],

  data () {
    return {
      isOpen: true,
      preventClickOutside: false,
      uid: generateComponentUid(this),
    }
  },

  computed: {
    openOverlayIds () {
      return this.$store.state.layout.openOverlayIds
    },

    hasScrollLock () {
      return this.openOverlayIds.includes(this.uid) && !this.isModalOnTop // Only add scroll-lock class if the modal is open, but not on top of the stack
    },

    isModalOnTop () {
      return this.openOverlayIds[this.openOverlayIds.length - 1] === this.uid
    },
  },

  mounted () {
    this.$store.commit('layout/ADD_OPEN_OVERLAY_ID', this.uid)
    window.addEventListener('mousedown', this.onMouseDown)

    this.$nextTick(() => {
      if (this.$refs.modal) {
        this.$refs.modal.focus()
        this.$refs.modal.scrollTop = 0
      }
    })
  },

  beforeUnmount () {
    window.removeEventListener('mousedown', this.onMouseDown)

    if (this.isOpen) {
      console.warn('ATTENTION: Modal component is not unloaded properly. Use modal.close() before removing the modal from the DOM via v-if. Check ModalEditTaskAssignee integration as example implementation.')
    }
  },

  unmounted () {
    this.$store.commit('layout/REMOVE_OPEN_OVERLAY_ID', this.uid)
  },

  methods: {
    // WARNING: close is also used from outside of the component
    close () {
      this.isOpen = false
    },

    onClickOutside (event) {
      // prevent modal from closing on outside click when click target on localhost is the vue dev tools bar
      if (isOnLocalhost && event.target.closest('#__vue-devtools-container__')) {
        return
      }

      if (!this.isModalOnTop) {
        return
      }

      if (this.preventClickOutside) {
        this.preventClickOutside = false
        return
      }

      this.onClickClose()
    },

    onClickClose () {
      if (document.getElementsByClassName('pintura-editor').length > 0) { // Hack to ensure, that the click-outside is not triggered if Pintura editor is open. We can't prevent the click-outside event to happen, because of the internal structure of the Pintura editor. It also ensures the correct behaviour of the "ESC" key handling if Pintura editor is open
        return
      }

      if (!this.showClose) {
        return
      }
      if (!this.didConfirmChanges()) {
        return
      }

      this.close()
    },

    didConfirmChanges () {
      if (!this.hasChanges) {
        return true
      }

      return confirm(this.$t('feature.unsaved_changes.confirmation_question'))
    },

    afterCloseAnimation () {
      this.$emit('after:close-animation')
    },

    onMouseDown (mouseEvent) {
      // Why onMouseDown handler to check if mouseUp happens outside of modal content?
      // When user triggers mousedown within modal content, but mouseup happens after moving the mouse outside of the modal, v-click-away triggers.
      // But whe have to block the v-click-away handler because the user does not want to close the modal in this special case
      // On touch devices this is not an issue as there is no mouse pointer which could be moved between mousedown and mouseup

      const modalMask = this.$refs['modal-window']

      // modalMask could be undefined because modal already unmounted before mouseup event fires
      if (modalMask) {
        const isClickInModalWindow = modalMask.contains(mouseEvent.target)
        this.preventClickOutside = isClickInModalWindow
      }
    },
  },
}
</script>
