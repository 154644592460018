export default class Participant {
  constructor ({
    _id,
    user,
    createdby,
    address,
    invitationAsProjectOwnerIsPending,
    roles,
    company,
    firstname,
    lastname,
    phone,
    note,
    enabled,
    permission,
    canapproverevisions,
    lastProjectAccess,
    uploadAlert = {},
    participantSelections,
    sendCopyToMyself,
    weekStartReport,
    planDocReport,
    taskReport,
    dueDateReport,
  }) {
    this._id = _id
    this.user = user
    this.createdby = createdby
    this.invitationAsProjectOwnerIsPending = invitationAsProjectOwnerIsPending
    this.roles = roles
    this.company = company
    this.firstname = firstname
    this.lastname = lastname
    this.phone = phone
    this.address = address
    this.note = note
    this.enabled = enabled
    this.permission = permission
    this.canapproverevisions = canapproverevisions
    this.lastProjectAccess = lastProjectAccess
    this.uploadAlert = uploadAlert
    this.participantSelections = participantSelections
    this.sendCopyToMyself = sendCopyToMyself
    this.weekStartReport = weekStartReport
    this.planDocReport = planDocReport
    this.taskReport = taskReport
    this.dueDateReport = dueDateReport

    this.computed = {
      isParticipant: true,
      isVirtual: false,
    }
  }
}
