import constants from '@/config/constants'

export default {
  namespaced: true,

  state: () => {
    return {
      htmlClassNames: [],
      isSupportBarOpen: false,
      windowWidth: window.innerWidth,
      openOverlayIds: [],
      openContextMenuComponents: [],
      routeBeforeSettings: undefined,
    }
  },

  getters: {
    hasMobileWidth (state) {
      return state.windowWidth <= constants.BREAKPOINTS.MOBILE
    },
  },

  mutations: {
    ADD_HTML_CLASS (state, htmlClassNames) {
      const addClasses = [...htmlClassNames.split(' ')]
      state.htmlClassNames = [...new Set([...state.htmlClassNames, ...addClasses])]
    },

    REMOVE_HTML_CLASS (state, htmlClassNames) {
      const removeClasses = [...htmlClassNames.split(' ')]
      state.htmlClassNames = [...state.htmlClassNames].filter(name => !removeClasses.includes(name))
    },

    OPEN_SUPPORT_BAR (state) {
      state.isSupportBarOpen = true
    },

    CLOSE_SUPPORT_BAR (state) {
      state.isSupportBarOpen = false
    },

    TOGGLE_SUPPORT_BAR (state) {
      state.isSupportBarOpen = !state.isSupportBarOpen
    },

    ADD_OPEN_OVERLAY_ID (state, overlayId) {
      if (!state.openOverlayIds.includes(overlayId)) {
        state.openOverlayIds.push(overlayId)
      }

      if (!state.htmlClassNames.includes('scroll-lock')) {
        state.htmlClassNames.push('scroll-lock')
      }
    },

    REMOVE_OPEN_OVERLAY_ID (state, overlayId) {
      if (state.openOverlayIds.includes(overlayId)) {
        state.openOverlayIds = state.openOverlayIds.filter(openOverlayId => overlayId !== openOverlayId)
      }

      if (state.openOverlayIds.length === 0) {
        state.htmlClassNames = state.htmlClassNames.filter(htmlClassName => htmlClassName !== 'scroll-lock')
      }
    },

    ADD_OPEN_CONTEXT_MENU_COMPONENT (state, contextMenuComponent) {
      state.openContextMenuComponents.push(contextMenuComponent)

      if (!state.htmlClassNames.includes('is-context-menu-open')) {
        state.htmlClassNames.push('is-context-menu-open')
      }
    },

    REMOVE_OPEN_CONTEXT_MENU_COMPONENT (state, contextMenuComponent) {
      state.openContextMenuComponents = state.openContextMenuComponents.filter(c => c !== contextMenuComponent)

      if (state.openContextMenuComponents.length === 0) {
        state.htmlClassNames.filter(c => c !== 'is-context-menu-open')
      }
    },

    SET_ROUTE_BEFORE_SETTINGS (state, route) {
      state.routeBeforeSettings = route
    },

    RESET_WINDOW_WIDTH (state) {
      state.windowWidth = window.innerWidth
    },
  },
}
